import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import AppRouter from './components/Router/AppRouter';
import AppLoading from './components/Loading/AppLoading';
import ModalCreateOrderContainer from 'containers/Order/ModalCreateOrderContainer';
import ErrorBoundary from 'creatella-react-components/lib/ErrorBoundary';
import Helmet from 'creatella-react-components/lib/Helmet';
import Alerts, { ALERTS_POSITION } from 'creatella-react-components/lib/Alerts';
import { handleAutoLogin, handleRefreshUserProfile, updateMOCompany, updateTraderCompany, handleLogout, updateLockUser, hideImportantData } from 'redux/reducers/auth';
import HELMET_DEFAULT_CONFIG from 'config/executors/i18n-resources/helmet';
import './AppContainer.scss';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { AUTH_API_URL, NOTIFICATION_TYPE, PUBLIC_EVENTS, PUSHER_KEY, STORAGEKEY_DEVICE_ID, ROUTES, BULK_INNER_NOTIFICATIONS, HOME_INIT_PRODUCTS } from 'config/constants';
import { getAuthToken } from 'redux/reducers/utils/auth/helpers';
import EventEmitter from 'utils/EventEmitter';
import ModalCancelOrderContainer from 'containers/Order/ModalCancelOrderContainer';
import { notifType, notify } from 'utils/notifSender';
// import LockScreen from 'react-lock-screen';
import LockerUi from 'containers/App/components/lockScreen/lockerUi';
import { lockUser, unlockUser } from 'api/auth';
import { withAsyncCaller } from 'creatella-react-components/lib/HOCs/withAsyncCaller';
import LockScreen from 'containers/App/components/lockScreen/lockScreen';
import LockLoading from 'containers/App/components/LockLoading/LockLoading';
import HeaderNav from './components/HeaderNav/HeaderNav';
import classNames from 'classnames';
import { updateQuoteScreen } from 'redux/reducers/quoteScreen';
import { updateIndicationsScreenBrokerages, updateIndicationsScreenClients, updateIndicationsScreenProducts } from 'redux/reducers/indicationsScreen';
import { getBrokerages } from 'api/brokerage';
import { getAllProductTypes, getProductTypes } from 'api/product-type';
import { updateOrderBookOrders } from 'redux/reducers/orderbook';
import { updateNegoScreenNegotiations } from 'redux/reducers/negoScreen';
import { getAllClients } from 'api/clients';
import DemoNav from './components/DemoNav/DemoNav';

window.Pusher = Pusher;

class AppContainer extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        autoLogin: PropTypes.func.isRequired,
        isAppReady: PropTypes.bool.isRequired,
        isAuthed: PropTypes.bool.isRequired,
        language: PropTypes.string.isRequired,
        profile: PropTypes.object.isRequired,
        updateTraderCompany: PropTypes.func.isRequired,
        updateMOCompany: PropTypes.func.isRequired,
        refreshProfile: PropTypes.func.isRequired,
        doLogout: PropTypes.func.isRequired,
        apiCaller: PropTypes.func.isRequired,
        LockUser: PropTypes.func.isRequired,
        isLocked: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        backdropActive: PropTypes.bool.isRequired,
        quoteScreenProductTypes: PropTypes.array.isRequired,
        updateQuoteScreen: PropTypes.func.isRequired,
        updateIndicationsScreenBrokerages: PropTypes.func.isRequired,
        updateIndicationsScreenClients: PropTypes.func.isRequired,
        updateIndicationsScreenProducts: PropTypes.func.isRequired,
        indicationsProducts: PropTypes.array.isRequired,
        indicationsBrokerages: PropTypes.array.isRequired,
        indicationsClients: PropTypes.array.isRequired,
        cleanUserData: PropTypes.func.isRequired,
        orderBookOrders: PropTypes.array.isRequired,
        updateOrderBookOrders: PropTypes.func.isRequired,
        negotiationScreenNegoItems: PropTypes.array.isRequired,
        updateNegoScreenNegotiations: PropTypes.func.isRequired,
        indicationsAllTraders: PropTypes.array.isRequired

    }

    state = {
        loading: false,
        lockIt: false,
        lockLoading: false
    }

    componentDidMount() {
        const { autoLogin } = this.props;

        this.EchoClient = null;
        this.setupRealtimeEvents();
        autoLogin();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { location, isAppReady, isAuthed, language, isLocked, backdropActive } = this.props;
        const { pathname } = location;
        const { lockIt, loading, lockLoading } = this.state;

        if (pathname !== nextProps.location.pathname ||
            isAppReady !== nextProps.isAppReady ||
            isAuthed !== nextProps.isAuthed ||
            language !== nextProps.language) {
            return true;
        }

        if (isLocked !== nextProps.isLocked) {
            if (nextProps.isLocked) {
                this.LockUserScreen();
            }

            return true;
        }

        if (lockIt !== nextState.lockIt ||
            loading !== nextState.loading ||
            lockLoading !== nextState.lockLoading) {
            return true;
        }

        if (backdropActive !== nextState.backdropActive) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isAuthed && prevProps.isAuthed) {
            if (prevProps.profile) {
                this.EchoClient.leaveChannel('App.Models.User.' + prevProps.profile.id);
            }
        }

        if (this.props.isAuthed && !prevProps.isAuthed) {
            this.setupRealtimeEvents();
        }

        if (this.props.profile !== prevProps.profile) {
            // user refresh the page so lock user
            if (this.props.profile.is_locked) {
                this.setState({ lockIt: true });
                this.props.history.push(ROUTES.HOME);
            }
        }
    }

    componentWillUnmount() {
        if (this.EchoClient && this.props.profile) {
            this.EchoClient.leaveChannel('App.Models.User.' + this.props.profile.id);
        }
    }

    onError = (error, info) => {
        Sentry.withScope((scope) => {
            scope.setExtras('Info', info);
            Sentry.captureException(error);
        });
    }

    setupRealtimeEvents() {
        const loginToken = getAuthToken();

        this.EchoClient && this.EchoClient.connector.pusher.disconnect();

        if (this.props.isAuthed) {
            this.EchoClient = new Echo({
                broadcaster: 'pusher',
                cluster: 'eu',
                authEndpoint: `${AUTH_API_URL}broadcasting/auth`,
                key: PUSHER_KEY,
                activityTimeout: 60000,
                auth: {
                    headers: {
                        Authorization: 'Bearer ' + loginToken
                    }
                },
                encrypted: true
            });

            this.EchoClient.private(`App.Models.User.${this.props.profile.id}`)
                .notification(this.handleReceiveNotification);

            const isDemoUser = this.props.profile.is_trader && this.props.profile.trader.company.is_demo;

            this.EchoClient.private(isDemoUser ? 'App.Models.User.Demo' : 'App.Models.User.All')
                .listen(PUBLIC_EVENTS.NEW_ORDER, (event) => {
                    EventEmitter.emit(PUBLIC_EVENTS.NEW_ORDER, event);
                })
                .listen(PUBLIC_EVENTS.ORDER_CANCEL, (event) => {
                    EventEmitter.emit(PUBLIC_EVENTS.ORDER_CANCEL, event);
                })
                .listen(PUBLIC_EVENTS.ORDER_UPDATE, (event) => {
                    EventEmitter.emit(PUBLIC_EVENTS.ORDER_UPDATE, event);
                })
                .listen(PUBLIC_EVENTS.ORDER_DONE, (event) => {
                    EventEmitter.emit(PUBLIC_EVENTS.ORDER_DONE, event);
                })

                .listen(PUBLIC_EVENTS.TRADING_DAY_RESTARTED, (event) => {
                    EventEmitter.emit(PUBLIC_EVENTS.TRADING_DAY_RESTARTED, event);
                    this.onTradingDayRestarted();
                })
                .listen(PUBLIC_EVENTS.ORDER_CANCELED_IN_BULK, (event) => {
                    EventEmitter.emit(PUBLIC_EVENTS.ORDER_CANCELED_IN_BULK, event);
                    this.onOrderCanceledInBulk(event);
                })

                .listen(PUBLIC_EVENTS.PRODUCT_TYPE_CREATED, (event) => {
                    this.updateProductTypeHandler(event);
                }).listen(PUBLIC_EVENTS.PRODUCT_TYPE_UPDATED, (event) => {
                    this.updateProductTypeHandler(event);
                }).listen(PUBLIC_EVENTS.PRODUCT_CREATED, (event) => {
                    this.updateProductTypeHandler(event);
                }).listen(PUBLIC_EVENTS.PRODUCT_UPDATED, (event) => {
                    EventEmitter.emit(PUBLIC_EVENTS.PRODUCT_UPDATED, event);
                    this.updateProductHandler(event);
                });
        }
    }

    handleEmitEvent = (eventType, eventData) => {
        EventEmitter.emit(eventType, {
            data: eventData,
            type: eventType
        });
    }

    handleReceiveNotification = async (event) => {
        EventEmitter.emit('NewNotificationReceived', event);

        const {
            indicationsBrokerages, indicationsClients, indicationsProducts, apiCaller,
            updateIndicationsScreenProducts, updateIndicationsScreenBrokerages, updateIndicationsScreenClients,
            orderBookOrders, updateOrderBookOrders, negotiationScreenNegoItems, updateNegoScreenNegotiations, profile,
            indicationsAllTraders
        } = this.props;

        if (event.type === NOTIFICATION_TYPE.DEMO_COMPANY_APPROVED) {
            if (this.props.profile.is_trader && this.props.profile.trader.company.is_demo) {
                this.props.refreshProfile();
            }
        }

        if (event.type === NOTIFICATION_TYPE.COMPANY_LIMIT_UPDATE) {
            // it should be handled for all screens!
            if (this.props.profile.is_trader) {
                this.props.updateTraderCompany(event.data);
            }

            if (this.props.profile.is_middle_office) {
                this.props.updateMOCompany(event.data);
            }
        }

        if (event.type === NOTIFICATION_TYPE.TRADER_UPDATED && this.props.profile.is_trader) {
            // this event should be handled here for all the screens (only trader side because it will be sent to MO as well)
            this.props.refreshProfile();
        }

        if (event.type === NOTIFICATION_TYPE.BAD_UPDATED && this.props.profile.is_bad) {
            // this event should be handled here for all the screens
            this.props.refreshProfile();
        }

        if (event.type === NOTIFICATION_TYPE.COMPANY_PRODUCT_ACTIVATED || event.type === NOTIFICATION_TYPE.COMPANY_PRODUCT_DEACTIVATED) {
            // this event should be handled here for all the screens
            this.props.refreshProfile();
        }

        if (event.type === NOTIFICATION_TYPE.MO_UPDATED && this.props.profile.is_middle_office) {
            // this event should be handled here for all the screens
            this.props.refreshProfile();
        }

        if (event.type === NOTIFICATION_TYPE.PTA_UPDATED && this.props.profile.is_admin && event.data.id === this.props.profile.id) {
            // this event should be handled here for all the screens
            this.props.refreshProfile();
        }

        if (event.type === NOTIFICATION_TYPE.BROKER_UPDATED && this.props.profile.is_broker) {
            // this event should be handled here for all the screens
            this.props.refreshProfile();
        }

        if (event.type === NOTIFICATION_TYPE.ACCOUNT_DEACTIVATE) {
            // this event should be handled here and log user out)
            notify('Your account is deactivated. You are being redirected outside your panel.', notifType.DANGER);

            setTimeout(() => {
                this.props.doLogout();
            }, 2000);
        }

        if (event.type === NOTIFICATION_TYPE.LOCKED_USER) {
            // this event should be handled here and lock user)
            const deviceKey = localStorage.getItem(STORAGEKEY_DEVICE_ID);

            if (event.data.device_id === deviceKey) {
                this.setState({ lockIt: true });
                this.props.history.push(ROUTES.HOME);
            }
        }

        if (event.type === NOTIFICATION_TYPE.UNLOCKED_USER) {
            // this event should be handled here and unlock user)
            const deviceKey = localStorage.getItem(STORAGEKEY_DEVICE_ID);

            if (event.data.device_id === deviceKey) {
                this.setState({ lockIt: false });
            }
        }

        if (event.type === NOTIFICATION_TYPE.PRODUCT_UPDATE_FOR_USER || event.type === NOTIFICATION_TYPE.ORDER_UPDATED) {
            this.handleOrderNotifications(event);
        }

        // notifications for bulk screens
        if (event.type === NOTIFICATION_TYPE.BROKERAGE_DEACTIVATED || event.type === NOTIFICATION_TYPE.CLIENT_DEACTIVATED) {
            if (profile.is_trader) {
                const removedBrokerageIndex = indicationsBrokerages.findIndex(b => b.id === event.data.brokerage.id);

                if (removedBrokerageIndex !== -1) {
                    // remove the brokerage from the list and the selected brokerages
                    updateIndicationsScreenBrokerages(indicationsBrokerages.filter((_, index) => index !== removedBrokerageIndex));

                    // update brokerages selected elements
                    const newEvent = { type: BULK_INNER_NOTIFICATIONS.BROKERAGE_REMOVED, data: { brokerage_id: event.data.brokerage.id } };

                    EventEmitter.emit('NewNotificationReceived', newEvent);
                }
            }

            if (profile.is_broker) {
                const removedClientIndex = indicationsClients.findIndex(b => b.id === event.data.company.id);

                if (removedClientIndex !== -1) {
                    // remove the brokerage from the list and the selected brokerages
                    updateIndicationsScreenClients(indicationsClients.filter((_, index) => index !== removedClientIndex), indicationsAllTraders.filter((trader) => trader.company_id !== event.data.company.id));

                    // update brokerages selected elements
                    const newEvent = { type: BULK_INNER_NOTIFICATIONS.CLIENT_DEACTIVATED, data: { company_id: event.data.company.id } };

                    EventEmitter.emit('NewNotificationReceived', newEvent);
                }
            }
        }

        if (event.type === NOTIFICATION_TYPE.BROKERAGE_UPDATED) {
            // this event triggers when mo change broker settings
            if (profile.is_trader) {
                const [brokerages] = await Promise.all([
                    await apiCaller(getBrokerages, { can_trade_only: true })
                ]);

                const brokerageListRendered = brokerages.data.data.map(brokerage => ({
                    ...brokerage,
                    mutual_product_ids: brokerage.product_types.map(mpt => mpt.id)
                }));

                const finalBrokerages = brokerageListRendered.filter(company => {
                    return !company.is_primary_broker_product_missing && !company.is_secondary_broker_product_missing;
                });

                updateIndicationsScreenBrokerages(finalBrokerages);

                // update brokerages selected elements
                const newEvent = { type: BULK_INNER_NOTIFICATIONS.BROKERAGES_UPDATED, data: { finalBrokerages } };

                EventEmitter.emit('NewNotificationReceived', newEvent);
            }

            if (profile.is_broker) {
                // reloading clients list as it should be checked on BE that this client should be shown or not
                const clients = await apiCaller(getAllClients);
                const allTraders = [];

                const clientsListRendered = clients.data.data.map(client => {
                    allTraders.push(...client.traders.map(trader => ({
                        ...trader,
                        company_name: client.name,
                        mutual_product_ids: trader.product_types.filter(p => client.product_types.findIndex(cp => cp.id === p.id) !== -1).map(pt => pt.id)
                    })));

                    return {
                        ...client,
                        traders: client.traders.map(trader => ({
                            ...trader,
                            company_name: client.name,
                            mutual_product_ids: trader.product_types.filter(p => client.product_types.findIndex(cp => cp.id === p.id) !== -1).map(pt => pt.id)
                        })),
                        mutual_product_ids: client.product_types.filter(pt => !pt.is_primary_broker_product_missing).map(pt => pt.id)

                    };
                });

                const finalClients = clientsListRendered.filter(company => {
                    return !company.is_primary_broker_product_missing;
                });

                updateIndicationsScreenClients(finalClients, allTraders);

                const newEvent = {
                    type: BULK_INNER_NOTIFICATIONS.CLIENTS_UPDATED,
                    data: {
                        finalClients,
                        allTraders
                    }
                };

                EventEmitter.emit('NewNotificationReceived', newEvent);
            }
        }

        if (event.type === NOTIFICATION_TYPE.BROKERAGE_ACTIVATED || event.type === NOTIFICATION_TYPE.CLIENT_ACTIVATED) {
            if (profile.is_trader) {
                // reloading brokerage list as it should be checked on BE that this brokerage should be shown or not
                const brokerages = await apiCaller(getBrokerages, { can_trade_only: true });

                const brokerageListRendered = brokerages.data.data.map(brokerage => ({
                    ...brokerage,
                    mutual_product_ids: brokerage.product_types.map(mpt => mpt.id)
                }));

                updateIndicationsScreenBrokerages(brokerageListRendered.filter(company => {
                    return !company.is_primary_broker_product_missing && !company.is_secondary_broker_product_missing;
                }));
            }

            if (profile.is_broker) {
                // reloading clients list as it should be checked on BE that this client should be shown or not
                const clients = await apiCaller(getAllClients);
                const allTraders = [];

                const clientsListRendered = clients.data.data.map(client => {
                    allTraders.push(...client.traders.map(trader => ({
                        ...trader,
                        company_name: client.name,
                        mutual_product_ids: trader.product_types.filter(p => client.product_types.findIndex(cp => cp.id === p.id) !== -1).map(pt => pt.id)
                    })));

                    return {
                        ...client,
                        traders: client.traders.map(trader => ({
                            ...trader,
                            company_name: client.name,
                            mutual_product_ids: trader.product_types.map(pt => pt.id)
                        })),
                        mutual_product_ids: client.product_types.filter(pt => !pt.is_primary_broker_product_missing).map(pt => pt.id)

                    };
                });

                updateIndicationsScreenClients(clientsListRendered.filter(company => {
                    return !company.is_primary_broker_product_missing;
                }), allTraders);
            }
        }

        if (event.type === NOTIFICATION_TYPE.COMPANY_PRODUCT_ACTIVATED) {
            // brokerage list should be updated as well

            const [productTypes, brokerages] = await Promise.all([
                await apiCaller(getAllProductTypes, { tradable_only: true, with_products: true }),
                await apiCaller(getBrokerages, { can_trade_only: true })
            ]);

            const brokerageListRendered = brokerages.data.data.map(brokerage => ({
                ...brokerage,
                mutual_product_ids: brokerage.product_types.map(mpt => mpt.id)
            }));

            const finalBrokerages = brokerageListRendered.filter(company => {
                return !company.is_primary_broker_product_missing && !company.is_secondary_broker_product_missing;
            });

            updateIndicationsScreenBrokerages(finalBrokerages);

            updateIndicationsScreenProducts(productTypes.data);

            // update brokerages selected elements
            const newEvent = { type: BULK_INNER_NOTIFICATIONS.BROKERAGES_UPDATED, data: { finalBrokerages } };

            EventEmitter.emit('NewNotificationReceived', newEvent);
        }

        if (profile.is_broker && event.type === NOTIFICATION_TYPE.BROKERAGE_PRODUCT_ACTIVATED) {
            // brokerage list should be updated as well

            const [productTypes, clients] = await Promise.all([
                await apiCaller(getAllProductTypes, { tradable_only: true, with_products: true }),
                await apiCaller(getAllClients)
            ]);

            const allTraders = [];

            const clientsListRendered = clients.data.data.map(client => {
                allTraders.push(...client.traders.map(trader => ({
                    ...trader,
                    company_name: client.name,
                    mutual_product_ids: trader.product_types.filter(p => client.product_types.findIndex(cp => cp.id === p.id) !== -1).map(pt => pt.id)
                })));

                return {
                    ...client,
                    traders: client.traders.map(trader => ({
                        ...trader,
                        company_name: client.name,
                        mutual_product_ids: trader.product_types.filter(p => client.product_types.findIndex(cp => cp.id === p.id) !== -1).map(pt => pt.id)
                    })),
                    mutual_product_ids: client.product_types.filter(pt => !pt.is_primary_broker_product_missing).map(pt => pt.id)

                };
            });
            const finalClients = clientsListRendered.filter(company => {
                return !company.is_primary_broker_product_missing;
            });

            updateIndicationsScreenClients(finalClients, allTraders);

            updateIndicationsScreenProducts(productTypes.data);

            // update brokerages selected elements
            const newEvent = {
                type: BULK_INNER_NOTIFICATIONS.CLIENTS_UPDATED,
                data: {
                    finalClients,
                    allTraders
                }
            };

            EventEmitter.emit('NewNotificationReceived', newEvent);
        }

        if (event.type === NOTIFICATION_TYPE.COMPANY_PRODUCT_DEACTIVATED || (event.type === NOTIFICATION_TYPE.BROKERAGE_PRODUCT_DEACTIVATED)) {
            updateIndicationsScreenProducts(indicationsProducts.filter(pt => pt.id !== event.data.product_type.id));

            const newEvent = { type: BULK_INNER_NOTIFICATIONS.PRODUCT_REMOVED, data: { product_id: event.data.product_type.id } };

            EventEmitter.emit('NewNotificationReceived', newEvent);
        }

        if (event.type === NOTIFICATION_TYPE.TRADER_UPDATED) {
            const productTypeIds = event.data.product_types.map(pt => pt.id);
            const currentProductTypeIds = indicationsProducts.map(p => p.id);
            const removedIds = currentProductTypeIds.filter(ptId => !productTypeIds.includes(ptId));
            const addedProducts = event.data.product_types.filter(product => !currentProductTypeIds.includes(product.id));

            const newEvent = { type: BULK_INNER_NOTIFICATIONS.PRODUCTS_UPDATED, data: { removedIds } };

            EventEmitter.emit('NewNotificationReceived', newEvent);

            if (removedIds.length) {
                updateIndicationsScreenProducts(indicationsProducts.filter(pt => productTypeIds.includes(pt.id)));
            }

            if (addedProducts.length) {
                const [productTypes, brokerages] = await Promise.all([
                    await apiCaller(getAllProductTypes, { tradable_only: true, with_products: true }),
                    await apiCaller(getBrokerages, { can_trade_only: true })
                ]);

                const brokerageListRendered = brokerages.data.data.map(brokerage => ({
                    ...brokerage,
                    mutual_product_ids: brokerage.product_types.map(mpt => mpt.id)
                }));

                const finalBrokerages = brokerageListRendered.filter(company => {
                    return !company.is_primary_broker_product_missing && !company.is_secondary_broker_product_missing;
                });

                updateIndicationsScreenBrokerages(finalBrokerages);

                updateIndicationsScreenProducts(productTypes.data);

                // update brokerages selected elements
                const newEvent = { type: BULK_INNER_NOTIFICATIONS.BROKERAGES_UPDATED, data: { finalBrokerages } };

                EventEmitter.emit('NewNotificationReceived', newEvent);
            }
        }

        // handle orderbook events

        if (event.type === NOTIFICATION_TYPE.ORDERBOOK_UPDATED) {
            const shouldShowOrder = event.data.order_book_should_shown;

            if (shouldShowOrder) {
                if (orderBookOrders.find(order => order.id === event.data.id)) {
                    const newOrdersState = orderBookOrders.map(order => {
                        if (order.id === event.data.id) {
                            return event.data;
                        }

                        return order;
                    }).sort(this.orderSorter);

                    updateOrderBookOrders(newOrdersState);
                } else {
                    const newOrdersState = [...orderBookOrders, event.data].sort(this.orderSorter);

                    updateOrderBookOrders(newOrdersState);
                }
            } else {
                const newOrdersState = orderBookOrders.filter(order => order.id !== event.data.id).sort(this.orderSorter);

                updateOrderBookOrders(newOrdersState);
            }
        }

        // handle negotiation screen events

        if (event.type === NOTIFICATION_TYPE.NEGO_AMEND || event.type === NOTIFICATION_TYPE.NEGO_FIRM_REQUESTED || event.type === NOTIFICATION_TYPE.NEGO_MAKER_ACCEPT_FIRM || event.type === NOTIFICATION_TYPE.NEGO_INIT ||
                event.type === NOTIFICATION_TYPE.NEGO_COUNTER || event.type === NOTIFICATION_TYPE.NEGO_MAKER_ADD_QTY || event.type === NOTIFICATION_TYPE.NEGO_QTY_REQUEST || event.type === NOTIFICATION_TYPE.NEGO_DONE ||
                event.type === NOTIFICATION_TYPE.NEGO_REJECT_FIRM || event.type === NOTIFICATION_TYPE.NEGO_CANCEL || event.type === NOTIFICATION_TYPE.NEGO_ACK || event.type === NOTIFICATION_TYPE.MESSAGE_READ || event.type === NOTIFICATION_TYPE.NEGO_HAS_MESSAGE) {
            let shouldItemDisplayed = true;
            let shouldRemoveItem = event.type === NOTIFICATION_TYPE.NEGO_CANCEL;
            const iAmMaker = event.data.order.is_mine;

            const isMakerTurn = event.data.trader_id === event.data.last_countered_by;
            const isMyTurn = iAmMaker ? isMakerTurn : !isMakerTurn;
            const iAmTaker = !iAmMaker;

            if (event.type === NOTIFICATION_TYPE.NEGO_INIT) {
                // if I am taker, I should not see the card!

                if (iAmTaker && event.data.is_requesting_firm) {
                    shouldItemDisplayed = false;
                }
            }

            if (event.type === NOTIFICATION_TYPE.NEGO_DONE) {
                if (isMyTurn) {
                    shouldItemDisplayed = false;
                    shouldRemoveItem = true;
                }
            }

            if (event.type === NOTIFICATION_TYPE.NEGO_ACK) {
                shouldItemDisplayed = false;
                shouldRemoveItem = true;
            }

            if (event.type === NOTIFICATION_TYPE.NEGO_COUNTER) {
                if (iAmTaker && !event.data.is_firm & event.data.is_firm_request_accepted === null && event.data.is_requesting_firm) {
                    shouldItemDisplayed = false;
                    shouldRemoveItem = true;
                }
            }

            if (shouldItemDisplayed && !shouldRemoveItem) {
                const itemExists = negotiationScreenNegoItems.findIndex(i => i.id === event.data.id) !== -1;

                if (itemExists) {
                    // update nego card

                    const negoItems = negotiationScreenNegoItems.map(item => {
                        if (item.id === event.data.id) {
                            return {
                                ...event.data,
                                shouldItemDisplayed
                            };
                        }

                        return item;
                    });

                    updateNegoScreenNegotiations(negoItems);
                } else {
                    // add the new nego card

                    const negoItems = [{ ...event.data, shouldItemDisplayed }, ...negotiationScreenNegoItems];

                    updateNegoScreenNegotiations(negoItems);
                }
            } else if (shouldRemoveItem) {
                const negoItems = negotiationScreenNegoItems.filter(f => f.id !== event.data.id);

                updateNegoScreenNegotiations(negoItems);
            }
        }

        // ExternalTradesMerged: it is handled in the notifications tray
        // if (event.type === NOTIFICATION_TYPE.EXTERNAL_TRADES_MERGED) {
        //     notify('External trades of ' + event.data.company.name + ' company merged successfully!', notifType.SUCCESS);
        // }
    };

    orderSorter = (a, b) => {
        let status = a.is_canceled - b.is_canceled;

        if (status === 0) {
            status = a.status - b.status;
        }

        return status === 0 ? b.is_firm - a.is_firm : status;
    };

    onTradingDayRestarted = () => {
        // belongs to the orderbook/negotiations screen logics
        updateOrderBookOrders([]);
        updateNegoScreenNegotiations([]);
    };

    onOrderCanceledInBulk = (event) => {
        // belongs to the orderbook logics
        const removedOrderIds = event.data.order_ids;
        const newOrdersState = this.props.orderBookOrders.filter(order => !removedOrderIds.includes(order.id) || (order.is_mine && order.is_same_company))
            .map(order => {
                order.is_canceled = removedOrderIds.includes(order.id) ? true : order.is_canceled;
                order.editable = removedOrderIds.includes(order.id) ? false : order.editable;
                order.cancelable = removedOrderIds.includes(order.id) ? false : order.cancelable;

                return order;
            })
            .sort(this.orderSorter);

        updateOrderBookOrders(newOrdersState);
    };

    updateProductHandler = async (event) => {
        // update the product data for quote screen

        const productTypeItems = this.props.quoteScreenProductTypes.map(pt => {
            if (pt.id === event.product_type_id) {
                const products = pt.products.map(product => {
                    if (product.id === event.id) {
                        return {
                            ...event,
                            buy_under_negotiation_count: product.buy_under_negotiation_count,
                            my_active_buy_orders_count: product.my_active_buy_orders_count,
                            my_active_sell_orders_count: product.my_active_sell_orders_count,
                            sell_under_negotiation_count: product.sell_under_negotiation_count,
                            product_type: product.product_type
                        };
                    }

                    return product;
                });

                return {
                    ...pt,
                    products
                };
            }

            return pt;
        });

        this.props.updateQuoteScreen(productTypeItems);
    };

    updateProductTypeHandler = async (event) => {
        // update the product data for quote screen

        const productTypes = await this.props.apiCaller(getProductTypes, { per_page: 100, with_products: true, products_per_page: HOME_INIT_PRODUCTS, tradable_only: true });

        this.props.updateQuoteScreen(productTypes.data.data);
    };

    handleOrderNotifications = async (event) => {
        if (event.type === NOTIFICATION_TYPE.PRODUCT_UPDATE_FOR_USER) {
            // update the product data
            const productTypeItems = this.props.quoteScreenProductTypes.map(pt => {
                if (pt.id === event.data.product_type_id) {
                    const products = pt.products.map(product => {
                        if (product.id === event.data.id) {
                            return {
                                ...event.data
                            };
                        }

                        return product;
                    });

                    return {
                        ...pt,
                        products
                    };
                }

                return pt;
            });

            this.props.updateQuoteScreen(productTypeItems);
        }

        if (event.type === NOTIFICATION_TYPE.ORDER_UPDATED) {
            EventEmitter.emit(NOTIFICATION_TYPE.ORDER_UPDATED + '_' + event.data.product_id, event);
        }
    };

    unlockUserScreen = async pin => {
        const { apiCaller, LockUser } = this.props;

        {
            this.setState({ loading: true });

            let isMounted = true;

            try {
                await apiCaller(unlockUser, pin);
                this.setState({ lockIt: false });
                LockUser(false);
                this.props.refreshProfile();

                notify('Unlock successful', notifType.SUCCESS);

                return { status: true };
            } catch (errs) {
                isMounted = !!errs;

                if (errs && errs.response) {
                    switch (errs.response.status) {
                        case 403:
                            return { status: false };

                        default:
                            break;
                    }
                } else {
                    notify('Error! Try again', notifType.DANGER);
                }
            } finally {
                if (isMounted) {
                    this.setState({ loading: false });
                }
            }
        }
    };

    LockUserScreen = async () => {
        const { apiCaller } = this.props;

        this.setState({ lockLoading: true });

        try {
            await apiCaller(lockUser);
            this.setState({ lockIt: true });
            this.props.history.push(ROUTES.HOME);
            this.props.cleanUserData();
        } catch (errs) {
            if (errs) {
                notify('Error! Try again', notifType.DANGER);
            }
        }

        this.setState({ lockLoading: false });
    }

    render() {
        const { isAppReady, location, isAuthed, language, backdropActive } = this.props;
        const { pathname } = location;
        const { lockIt, lockLoading } = this.state;

        const shouldRenderHeader = pathname === ROUTES.NEGOTIATION ||
        pathname === ROUTES.ORDER_BOOK ||
        pathname.includes(ROUTES.SETTINGS) ||
        pathname.includes(ROUTES.MANAGEMENT) ||
        pathname === ROUTES.REPORTS ||
        pathname === ROUTES.BULK ||
        pathname === ROUTES.INDICATIONS ||
        pathname === ROUTES.HOME ||
        pathname === ROUTES.DATA_API ||
        pathname === ROUTES.WELCOME_MO ||
        pathname === ROUTES.FUTURES ||
        pathname === ROUTES.TENDERS;

        if (isAppReady) {
            return (
                <main className={classNames('AppContainer', { 'AppContainer--no-scroll': backdropActive })}>
                    <ReactNotifications className='pt-notifs' />
                    {backdropActive ? <div className={'AppContainer__backdrop'} /> : null}
                    <ErrorBoundary
                        pathname={pathname}
                        onError={this.onError}>
                        {shouldRenderHeader ? <HeaderNav/> : null}
                        {shouldRenderHeader && this.props.profile.is_trader && this.props.profile.trader.company.is_demo ? <DemoNav/> : null}

                        <AppRouter isAuthed={isAuthed} />
                    </ErrorBoundary>

                    <Helmet
                        pathname={pathname}
                        defaultConfig={HELMET_DEFAULT_CONFIG[language]} />

                    <Alerts
                        position={ALERTS_POSITION.BOTTOM_LEFT} />

                    {isAuthed && <ModalCreateOrderContainer />}
                    {isAuthed && <ModalCancelOrderContainer />}

                    {isAuthed && <LockScreen
                        LockUserScreen={this.LockUserScreen}
                        lockIt={lockIt}
                        setLockIt={e => this.setState({ lockIt: e })}>
                        <LockerUi
                            submitForm={this.unlockUserScreen}
                            loading={this.state.loading} />
                    </LockScreen> }
                    <LockLoading loading={lockLoading} />
                </main>
            );
        }

        return (
            <main className={classNames('AppContainer', { 'AppContainer--no-scroll': backdropActive })}>
                <Helmet
                    pathname={pathname}
                    defaultConfig={HELMET_DEFAULT_CONFIG[language]} />

                <AppLoading showLogo={true} />
            </main>
        );
    }
}

const mapStateToProps = ({ auth, i18n, settings, quoteScreen, indicationsScreen, orderbook, negoScreen }) => {
    const { isAppReady, isAuthed, profile, isLocked } = auth;
    const { language } = i18n;
    const { backdropActive } = settings;
    const { productTypes } = quoteScreen;
    const indicationsProducts = indicationsScreen.products;
    const indicationsBrokerages = indicationsScreen.brokerages;
    const indicationsClients = indicationsScreen.clients;
    const indicationsAllTraders = indicationsScreen.allTraders;

    const orderBookOrders = orderbook.orders;
    const negotiationScreenNegoItems = negoScreen.negoItems;

    return {
        isAppReady,
        isAuthed,
        language,
        profile,
        isLocked,
        backdropActive,
        quoteScreenProductTypes: productTypes,
        indicationsProducts,
        indicationsBrokerages,
        indicationsClients,
        orderBookOrders,
        negotiationScreenNegoItems,
        indicationsAllTraders
    };
};

const mapDispatchToProps = (dispatch) => ({
    autoLogin: (location) => {
        dispatch(handleAutoLogin(location));
    },
    updateTraderCompany: (company) => {
        dispatch(updateTraderCompany(company));
    },
    updateMOCompany: (company) => {
        dispatch(updateMOCompany(company));
    },
    refreshProfile: () => {
        dispatch(handleRefreshUserProfile());
    },
    doLogout: () => {
        dispatch(handleLogout());
    },
    LockUser: e => {
        dispatch(updateLockUser(e));
    },
    updateQuoteScreen: (productTypes) => {
        dispatch(updateQuoteScreen(productTypes));
    },
    updateIndicationsScreenProducts: (products) => {
        dispatch(updateIndicationsScreenProducts(products));
    },
    updateIndicationsScreenBrokerages: (brokerages) => {
        dispatch(updateIndicationsScreenBrokerages(brokerages));
    },
    updateIndicationsScreenClients: (clients, traders) => {
        dispatch(updateIndicationsScreenClients(clients, traders));
    },
    cleanUserData: () => {
        dispatch(hideImportantData());
    },
    updateOrderBookOrders: (orders) => {
        dispatch(updateOrderBookOrders(orders));
    },
    updateNegoScreenNegotiations: (negoItems) => {
        dispatch(updateNegoScreenNegotiations(negoItems));
    }
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withAsyncCaller(AppContainer))
);
