import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'containers/App/components/Modal/Modal';
import AppLoadingOverlay from 'containers/App/components/Loading/AppLoadingOverlay';
import Button from 'creatella-react-components/lib/Button';
import './ModalConfirmCreateOrderScreen.scss';
import BrokerageSelect from 'containers/App/components/BrokerSelect/BrokerageSelect';
import { ORDER_TYPE, USER_ROLES } from 'config/constants';
import warning from 'assets/images/general/warning.svg';

ModalConfirmCreateOrderScreen.propTypes = {
    orderToCreate: PropTypes.object.isRequired,
    isVisible: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isProcessing: PropTypes.bool,
    brokeragesList: PropTypes.array,
    roleType: PropTypes.number.isRequired,
    isBlock: PropTypes.bool.isRequired
};

function ModalConfirmCreateOrderScreen({
    isVisible,
    isProcessing,
    handleClose,
    handleBack,
    handleSubmit,
    orderToCreate,
    brokeragesList,
    roleType,
    isBlock
}) {
    const volume = (!orderToCreate.volume.value && orderToCreate.volume.isDirty) || (!orderToCreate.default_volume && !orderToCreate.volume.isDirty)
        ? null
        : (`${orderToCreate.volume.isDirty
            ? (orderToCreate.volume.value === '' ? 0 : orderToCreate.volume.value)
            : orderToCreate.default_volume}`);

    const volumePerBroker = volume ? Number(volume) : 'No Qty';
    const brokerageQtyDisplay = orderToCreate.is_firm.value ? volumePerBroker.toLocaleString() + ' ' + orderToCreate.units?.toUpperCase() : `up to ${volumePerBroker.toLocaleString()} ${orderToCreate.units?.toUpperCase()}`;
    const totalVolume = roleType === USER_ROLES.TRADER ? volume ? (volume * orderToCreate.brokerages.value.length).toLocaleString() + ' ' + orderToCreate.units?.toUpperCase() : 'No Qty' : null;
    const orderPrice = orderToCreate.price.isDirty ? orderToCreate.price.value : orderToCreate.default_price;

    let showBidGreaterOfferWarning = false;
    let showOfferLowerBidWarning = false;

    if (orderToCreate.type === ORDER_TYPE.BUY && !orderToCreate.is_firm.value && orderToCreate.best_sell_price && orderPrice > orderToCreate.best_sell_price) {
        showBidGreaterOfferWarning = true;
    }

    if (orderToCreate.type === ORDER_TYPE.SELL && !orderToCreate.is_firm.value && orderToCreate.best_buy_price && orderPrice < orderToCreate.best_buy_price) {
        showOfferLowerBidWarning = true;
    }

    return (
        <Modal
            className='modal-confirm-create-order-screen'
            onClose={handleClose}
            isVisible={isVisible}
            title={() => {
                return (
                    <div className='modal-confirm-create-order-screen__header'>
                        <Button
                            className='modal-confirm-create-order-screen__back-button '
                            onClick={handleBack}/>
                    </div>
                );
            }}>
            {isProcessing && <AppLoadingOverlay />}
            <div className='confirm-create-order__wrapper'>
                <div className='confirm-create-order__product-info-name'>{orderToCreate.name}</div>
                {roleType === USER_ROLES.BROKERAGE ? <div className='confirm-create-order__company-name'>Primary Client: {orderToCreate.company_name.value}</div> : null}
                {roleType === USER_ROLES.BROKERAGE ? <div className='confirm-create-order__company-name'>Primary Trader: {orderToCreate.trader_name.value}</div> : null}

                {roleType === USER_ROLES.BROKERAGE && isBlock ? <div className='confirm-create-order__company-name'>Secondary Client: {orderToCreate.secondary_company_name.value}</div> : null}
                {roleType === USER_ROLES.BROKERAGE && isBlock ? <div className='confirm-create-order__company-name'>Secondary Trader: {orderToCreate.secondary_trader_name.value}</div> : null}

                <div className='confirm-create-order__product-wrapper'>
                    <div className='confirm-create-order__product-info'>
                        <div className='confirm-create-order__left-info'>
                            <span
                                className={`confirm-create-order__product-info-type ${orderToCreate.type === 1
                            ? 'confirm-create-order__product-info-type--buy'
                            : 'confirm-create-order__product-info-type--sell'}`}>
                                {orderToCreate.type === 1 ? 'Buy' : 'Sell'}
                            </span>
                            <span>@</span>
                            <span className='confirm-create-order__product-info-price'>
                                {orderPrice}
                            </span>
                            <span className='confirm-create-order__product-info-currency'>
                                {orderToCreate.currency}
                            </span>
                        </div>
                        <div className='confirm-create-order__right-info' >

                            <span className='confirm-create-order__product-info-volume'>
                                {volume === null ? 'No Qty' : Number(volume).toLocaleString() + ' ' + orderToCreate.units?.toUpperCase()}
                            </span>
                        </div>
                    </div>
                    {roleType === USER_ROLES.TRADER ? <div className='confirm-create-order__brokerages-list'><BrokerageSelect isBlock={isBlock} qty={volume ? brokerageQtyDisplay : null}
                        readOnly={true} defaultSelectedBrokerages={orderToCreate.brokerages.value}
                        brokeragesList={brokeragesList} /> </div> : null}
                    {roleType === USER_ROLES.TRADER && !isBlock ? <div className='confirm-create-order__total-volume'><div>Total Quantity</div> <div>{!orderToCreate.is_firm.value && volume ? 'max ' : null}{totalVolume}</div></div> : null}
                    {/* {roleType === USER_ROLES.TRADER && isBlock ? <div className={classNames('confirm-create-order__broker-commission', { 'confirm-create-order__broker-commission--margin': orderToCreate.brokerages.value.length > 0 })}>{orderToCreate.brokerages.value.length > 0 ? 'The broker will receive 50% of their normal commission for all the standard post-trade services for this blocktrade' : 'You will be charged 25% of the normal commission as a platform fee by Paper Trader to you, but there will not be any post-trade services'}</div> : null} */}

                </div>
                <div className='confirm-create-order__product-wrapper'>
                    <div className='confirm-create-order__product-info-firm-wrapper'>
                        <div className='confirm-create-order__product-info-firm-dot' />
                        <div className='confirm-create-order__product-info-firm'>
                            {orderToCreate.is_firm.value ? 'Firm' : 'Indicative'} {orderToCreate.is_visible.value === false ? <div className='confirm-create-order__order-hidden'>- Name Undisclosed </div> : null}
                        </div>
                    </div>
                    {showBidGreaterOfferWarning ? <div className='confirm-create-order__warning-box'>
                        <img className='confirm-create-order__warning-icon' src={warning} alt='error' />
                        <div className='confirm-create-order__warning-text'>Warning: You are creating a bid greater then best offer!</div>
                    </div> : null}

                    {showOfferLowerBidWarning ? <div className='confirm-create-order__warning-box'>
                        <img className='confirm-create-order__warning-icon' src={warning} alt='error' />
                        <div className='confirm-create-order__warning-text'>Warning: You are creating an offer lower then best bid!</div>
                    </div> : null}
                    { orderToCreate.note.value.trim() ? <>
                        <div className='confirm-create-order__label-condition'>Conditions</div>
                        <div className='confirm-create-order__product-info-note'>
                            {orderToCreate.note.value}
                        </div>
                    </> : null}
                    <Button className='confirm-create-order__button-confirm' onClick={handleSubmit}>
                        Confirm
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalConfirmCreateOrderScreen;
