import { TRADING_ELOQUENT as ELOQUENT } from 'config/constants';

/**
 * Get products
 * @param {Object} data
 * @returns {Promise}
 */
export function getBrokerages(data, { token }) {
    return ELOQUENT.Brokerages.index({
        ...data,
        per_page: 100
    }, token);
}

/**
 * Get Brokerages
 * @param {Object} data
 * @returns {Promise}
 */
export function getAllBrokerages(data, { token }) {
    return ELOQUENT.Brokerages.all(data, token);
}

/**
 * add many Brokerages
 * @param {Array} ids
 * @returns {Promise}
 */

export function addManyBrokerages(ids, { token }) {
    return ELOQUENT.Brokerages.storeMany(ids, token);
}

/**
 * activate a Brokerage
 * @param {Number} id
 * @returns {Promise}
 */
export function activateBrokerage(id, { token }) {
    return ELOQUENT.Brokerages.activate(id, token);
}

/**
 * deactivate a Brokerage
 * @param {Number} id
 * @returns {Promise}
 */
export function deactivateBrokerage(id, { token }) {
    return ELOQUENT.Brokerages.deactivate(id, token);
}

/**
 * update a Brokerage
 * @param {Number} id
 * @returns {object}  data
 */

export function updateBrokerage(id, data, { token }) {
    return ELOQUENT.Brokerages.update(id, data, token);
}

/**
 * approve a Brokerage
 * @param {Number} id
 * @returns {Promise}
 */
export function approveBrokerage(id, { token }) {
    return ELOQUENT.Brokerages.approve(id, token);
}

/**
 * create an external brokerage
 * @param {Object} data
 * @returns {Promise}
 */
export function createExternalBrokerage(data, { token }) {
    return ELOQUENT.Brokerages.storeExternal(data, token);
}

/**
 * update an external brokerage
 * @param {Number} brokerageId
 * @param {Object} data
 * @returns {Promise}
 */
export function updateExternalBrokerage(brokerageId, data, { token }) {
    return ELOQUENT.Brokerages.updateExternal(brokerageId, data, token);
}
