import { useCallback, useRef } from 'react';

export default function useComponentSubscriber() {
    const subscribers = useRef(new Set());

    const setSubscriber = useCallback((callBack = () => null) => {
        subscribers.current.add(callBack);

        return () => subscribers.current.delete(callBack);
    }, []);

    const getSubscribers = useCallback(() => subscribers.current, []);

    const notifySubscribers = useCallback((...args) => {
        subscribers.current.forEach(callback => callback(args));
    }, []);

    return {
        subscribers,
        setSubscriber,
        getSubscribers,
        notifySubscribers
    };
}
