
export const DEFAULT = 'An unknown error occured, please try again in a minute.';

export const AUTH_LOGIN_401 = 'Incorrect email or password. Please try again';
export const AUTH_LOGIN_422 = 'Please verify your email first by following the instructions sent to you via email.';
export const AUTH_REGISTER_422 = 'An account with this E-mail address already exists.';

export const PASSWORD_FORGOT_404 = 'No account found with that email address.';
export const PASSWORD_RESET_404 = 'Invalid link, make sure you copied the whole password reset link from your email.';
export const PASSWORD_RESET_422 = 'This link already expired.';
