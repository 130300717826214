import PropTypes from 'prop-types';
import React from 'react';
import Modal from './Modal';
import warning from 'assets/images/general/warning.svg';
import Button from 'creatella-react-components/lib/Button';
import './ModalError.scss';

ModalLimitError.propTypes = {
    message: PropTypes.string.isRequired,
    limit: PropTypes.string,
    onClose: PropTypes.func,
    isVisible: PropTypes.bool
};

function ModalLimitError({
    onClose,
    message,
    limit,
    isVisible
}) {
    return (
        <Modal
            onClose={onClose}
            isVisible={isVisible}
            className='modal-error'
            title={() => <div className='modal-error__header'>
                <img src={warning} alt='error' />
            </div>}>
            <div className='modal-error__message'>{message}<b>{limit}</b></div>
            <div className='modal-error__button-wrapper'>
                <Button
                    onClick={onClose}
                    className='modal-error__button modal-error__button--close'>
                    Close
                </Button>
            </div>
        </Modal>
    );
}

export default ModalLimitError;
