import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import isNumeric from 'validator/lib/isNumeric';
import ActivityIndicator from 'creatella-react-components/lib/ActivityIndicator';

import './LockerUi.scss';

LockerUi.propTypes = {
    submitForm: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

function LockerUi({ submitForm, loading }) {
    const [firstInput, setFirstInput] = useState('');
    const [secondInput, setSecondInput] = useState('');
    const [thirdInput, setThirdInput] = useState('');
    const [fourthInput, setFourthInput] = useState('');
    const [error, setError] = useState(false);
    const [errmsg, setErrmsg] = useState('* Pin is required!');

    const formRef = useRef(null);
    const lockBox = useRef(null);

    const handleKeyDown = (e, name) => {
        if (e.keyCode === 39) {
            // go next

            switch (name) {
                case 'firstInput':

                    formRef.current.secondInput.focus();

                    break;

                case 'secondInput':
                    formRef.current.thirdInput.focus();

                    break;

                case 'thirdInput':
                    formRef.current.fourthInput.focus();

                    break;

                case 'fourthInput':
                    formRef.current.subtn.focus();

                    break;

                default:
                    break;
            }
        }

        if (e.keyCode === 37) {
            // go prev
            switch (name) {
                case 'secondInput':
                    formRef.current.firstInput.focus();

                    break;

                case 'thirdInput':
                    formRef.current.secondInput.focus();

                    break;

                case 'fourthInput':
                    formRef.current.thirdInput.focus();

                    break;
                case 'subtn':
                    formRef.current.fourthInput.focus();

                    break;

                default:
                    break;
            }
        }
    };

    const handleInput = async (e, name) => {
        const val = e.target.value;

        setError(false);

        if ((isNumeric(val) && val < 10) || val === '') {
            switch (name) {
                case 'firstInput':
                    setFirstInput(val);

                    if (val !== '') {
                        formRef.current.secondInput.focus();
                    }

                    break;

                case 'secondInput':
                    setSecondInput(val);

                    if (val !== '') {
                        formRef.current.thirdInput.focus();
                    }

                    break;

                case 'thirdInput':
                    setThirdInput(val);

                    if (val !== '') {
                        formRef.current.fourthInput.focus();
                    }

                    break;

                case 'fourthInput':
                    setFourthInput(val);

                    if (val !== '') {
                        formRef.current.subtn.focus();
                    }

                    break;

                default:
                    break;
            }
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();

        if (firstInput === '' || secondInput === '' || thirdInput === '' || fourthInput === '') {
            setErrmsg('* Pin is required!');
            setError(true);
        } else {
            const pin = String(firstInput + secondInput + thirdInput + fourthInput);

            const resp = await submitForm(pin);

            if (resp && !resp.status) {
                setErrmsg('Wrong pin');
                setError(true);
                shakeBox();
            }
        }
    };

    useEffect(() => {
        formRef.current.firstInput.focus();
    }, []);

    const shakeBox = e => {
        if (e) {
            e.preventDefault();

            if (e.target === e.currentTarget) {
                lockBox.current.classList.add('shakeIt');

                setTimeout(() => {
                    if (lockBox && lockBox.current) {
                        lockBox.current.classList.remove('shakeIt');
                    }
                }, 1000);
            }
        } else {
            lockBox.current.classList.add('shakeIt');

            setTimeout(() => {
                lockBox.current.classList.remove('shakeIt');
            }, 1000);
        }

        formRef.current.firstInput.focus();
    };

    const protectChildFromClick = e => {
        e.stopPropagation();
    };

    return (<div
        onClick={shakeBox}
        className='react-lock-screen-ui'>
        <div className={!error ? 'react-lock-screen-ui-box' : 'react-lock-screen-ui-box error-ui-box'} ref={lockBox} onClick={protectChildFromClick} >
            <span className='react-lock-screen-ui__title'>Enter your pin to unlock</span>
            <div className='react-lock-screen-ui__form'>
                <form ref={formRef} onSubmit={handleSubmit}>
                    <input
                        maxLength={1}
                        className='react-lock-screen-ui-input'
                        name='firstInput'
                        type='number'
                        onChange={e => handleInput(e, 'firstInput')}
                        onKeyDown={e => handleKeyDown(e, 'firstInput')}
                        value={firstInput}/>
                    <input name='secondInput'
                        maxLength={1}
                        className='react-lock-screen-ui-input'
                        type='number'
                        onChange={e => handleInput(e, 'secondInput')}
                        onKeyDown={e => handleKeyDown(e, 'secondInput')}
                        value={secondInput}/>
                    <input name='thirdInput'
                        maxLength={1}
                        className='react-lock-screen-ui-input'
                        type='number'
                        onChange={e => handleInput(e, 'thirdInput')}
                        onKeyDown={e => handleKeyDown(e, 'thirdInput')}
                        value={thirdInput}/>
                    <input name='fourthInput'
                        maxLength={1}
                        className='react-lock-screen-ui-input'
                        type='number'
                        onChange={e => handleInput(e, 'fourthInput')}
                        onKeyDown={e => handleKeyDown(e, 'fourthInput')}
                        value={fourthInput}/>
                    <button
                        type='submit'
                        className='react-lock-screen-ui-btn'
                        name='subtn'
                        onKeyDown={e => handleKeyDown(e, 'subtn')}
                        disabled={loading}>
                        { loading ? <ActivityIndicator />
                            : 'UNLOCK'
                        }
                    </button>
                </form>
            </div>
            {error && <div className='react-lock-screen-ui__err'>
                {errmsg}
            </div>}
        </div>
    </div>);
}

export default LockerUi;
