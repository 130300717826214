import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import './OrderMenu.scss';
import { useOnClickOutside } from 'utils/helpers';
import { ORDER_MENU_STATUS, ROUTES } from 'config/constants';
import { useHistory } from 'react-router-dom';

OrderMenu.propTypes = {
    orderMenuStatus: PropTypes.number.isRequired,
    setOrderMenuStatus: PropTypes.func.isRequired,
    orderLinkRef: PropTypes.oneOfType([
        // Either a function
        PropTypes.func,
        // Or the instance of a DOM native element (see the note about SSR)
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    createOrder: PropTypes.func.isRequired,
    closeHamburgerMenu: PropTypes.func.isRequired,
    updateBackdrop: PropTypes.func.isRequired
};

function OrderMenu({
    orderMenuStatus,
    setOrderMenuStatus,
    orderLinkRef,
    createOrder,
    closeHamburgerMenu,
    updateBackdrop
}) {
    const isVisible = orderMenuStatus !== ORDER_MENU_STATUS.HIDDEN;
    const orderMenuRef = useRef();
    const history = useHistory();

    useOnClickOutside(orderMenuRef, useCallback(() => {
        setOrderMenuStatus(ORDER_MENU_STATUS.HIDDEN);
        updateBackdrop(false);
    }, [setOrderMenuStatus, updateBackdrop]), orderLinkRef);

    const onNewOrderClick = () => {
        createOrder(false);
        setOrderMenuStatus(ORDER_MENU_STATUS.HIDDEN);
        closeHamburgerMenu();
    };

    const onNewBlockOrderClick = () => {
        createOrder(true);
        setOrderMenuStatus(ORDER_MENU_STATUS.HIDDEN);
        closeHamburgerMenu();
    };

    const onNewBulkOrdersClick = () => {
        history.replace(ROUTES.BULK);
        setOrderMenuStatus(ORDER_MENU_STATUS.HIDDEN);
        closeHamburgerMenu();
    };

    return (
        !isVisible ? false
            : <div className={'order-menu'}>
                <div ref={orderMenuRef} className={'order-menu__container'}>
                    <div className='order-menu__pointer' />
                    <div className={'order-menu__wrapper'}>
                        <div className={'order-menu__body'} >
                            <div className={'order-menu__row'} onClick={onNewOrderClick}>New Single Order</div>
                            <div className={'order-menu__separator'}/>
                            <div className={'order-menu__row'} onClick={onNewBulkOrdersClick}>New Bulk Order</div>
                            <div className={'order-menu__separator'}/>
                            <div className={'order-menu__row'} onClick={onNewBlockOrderClick}>New Block Order</div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default OrderMenu;
