import React from 'react';
import PropTypes from 'prop-types';
import ActivityIndicator from 'creatella-react-components/lib/ActivityIndicator';
import './LockLoading.scss';

LockLoading.propTypes = {
    loading: PropTypes.bool.isRequired
};

function LockLoading({ loading }) {
    return (
        loading
            ? <div className='appLock'>
                <ActivityIndicator size={40} />
            </div> : '');
}

export default LockLoading;
