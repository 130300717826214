import { TRADING_ELOQUENT as ELOQUENT } from 'config/constants';

/**
 * Get trader my limits
 * @returns {Promise}
 */
export function getMyLimits({ token }) {
    return ELOQUENT.MyLimits.index(undefined, token);
}

/**
 * Get total product limits
 * @param {Object} data
 * @returns {Promise}
 */
export function getTotalProducts(data) {
    return ELOQUENT.MyProductTypes.index(undefined, data.token);
}

/**
 * Get trader cp limits
 * @param {Object} data
 * @returns {Promise}
 */
export function getTraderConterpartyLimits(data, { token }) {
    return ELOQUENT.Counterparties.index(data, token);
}

/**
 * Get trader cp limits
 * @returns {Promise}
 */
export function getTraderConterpartyProductLimits({ token }) {
    return ELOQUENT.CounterpartiesProductTypes.index(null, token);
}

/**
 * Get all traders limits\
 * @param {Object} data
 * @returns {Promise}
 */
export function getMyTradersLimits(data) {
    return ELOQUENT.MyTraders.limits(undefined, data.token);
}

/**
 * update total product limits
 * @param {Object} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateTotalProductLimits(id, data) {
    return ELOQUENT.MyProductTypes.update(id, data);
}

/**
 * update my company limits
 * @param {Object} data
 * @returns {Promise}
 */
export function updateMyCompanyLimit(data) {
    return ELOQUENT.MyCompany.updateLimit(data);
}

/**
 * update total product limits
 * @param {Object} traderId
 * @param {Object} productTypeId
 * @param {Object} data
 * @returns {Promise}
 */
export function updateTraderProductLimit(traderId, productTypeId, data) {
    return ELOQUENT.MyTraders.updateLimit(traderId, productTypeId, data);
}

/**
 * update cp product type limits
 * @param {Object} counterpartyId
 * @param {Object} productTypeId
 * @param {Object} data
 * @returns {Promise}
 */
export function updateCounterpartyProductLimit(counterpartyId, productTypeId, data) {
    return ELOQUENT.CounterpartiesProductTypes.update(counterpartyId, productTypeId, data);
}

/**
 * update cp limits
 * @param {Object} counterpartyId
 * @param {Object} data
 * @returns {Promise}
 */
export function updateCounterpartyLimit(counterpartyId, data) {
    return ELOQUENT.Counterparties.update(counterpartyId, data);
}
