import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'containers/App/components/Modal/Modal';
import AppLoadingOverlay from 'containers/App/components/Loading/AppLoadingOverlay';
import Button from 'creatella-react-components/lib/Button';
import './ModalCancelOrderScreen.scss';

ModalCancelOrderScreen.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    yesButtonText: PropTypes.string,
    noButtonText: PropTypes.string,
    isVisible: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    isProcessing: PropTypes.bool
};

function ModalCancelOrderScreen({
    isVisible,
    isProcessing,
    handleClose,
    handleSubmit,
    title,
    message,
    yesButtonText,
    noButtonText
}) {
    return (
        <Modal
            title={title}
            isVisible={isVisible}
            onClose={handleClose}
            className='modal-cancel-order-screen'>
            <form className='cancel-order'>
                <div className='cancel-order__message'>{message}</div>
                <Button onClick={handleSubmit} className={'cancel-order__submit-button cancel-order__submit-button--red'}>{yesButtonText}</Button>

                {noButtonText ? <Button onClick={handleClose} className='cancel-order__submit-button cancel-order__submit-button--white'>{noButtonText}</Button> : null}
            </form>
            {isProcessing && <AppLoadingOverlay />}
        </Modal>
    );
}

export default ModalCancelOrderScreen;
