/* eslint-disable camelcase */
import { AUTH_ELOQUENT as ELOQUENT } from 'config/constants';

/**
 * Get notifications
 * @param {Any} per_page
 * @param {Any} page
 * @returns {Promise}
 */
export function getNotifications(per_page = 100, page = 1, { token }) {
    return ELOQUENT.Notifications.index({ per_page, page }, token);
}

/**
 * Read all notifications
 * @returns {Promise}
 */
export function readAllNotifications() {
    return ELOQUENT.Notifications.readAll();
}

/**
 * Read a notification
 * @param {Any} id
 * @returns {Promise}
 */
export function readNotification(id) {
    return ELOQUENT.Notifications.read(id);
}

/**
 * Read group of notifications
 * @param {Any} ids
 * @returns {Promise}
 */
export function readNotifications(ids) {
    return ELOQUENT.Notifications.readMany(ids);
}
