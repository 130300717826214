import { TRADING_ELOQUENT as ELOQUENT } from 'config/constants';

/**
 * Get logged in user's profile data
 * @returns {Promise}
 */
export function getAuthedProfile() {
    return ELOQUENT.User.me();
}

/**
 * Update a user's profile data
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateUserProfile(id, data) {
    return ELOQUENT.User.updateOne(id, data);
}

/**
 * Get all users
 * @param {Object} data
 * @returns {Promise}
 */
export function getAllUsers(data, { token }) {
    return ELOQUENT.User.all(data, token);
}

/**
 * Get all users
 * @param {Object} data
 * @returns {Promise}
 */
export function profileUpdate(data, { token }) {
    return ELOQUENT.User.updateProfile(data, token);
}

/**
 * Get all users
 * @param {String} step
 * @returns {Promise}
 */
export function updateTutorial(step, { token }) {
    return ELOQUENT.User.updateTutorialStep({ step }, token);
}
