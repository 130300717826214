export const UPDATE_NEGO_SCREEN_NEGOS = 'UPDATE_NEGO_SCREEN_NEGOS';
export const UPDATE_NEGO_SCREEN_DATA = 'UPDATE_NEGO_SCREEN_DATA';

const INITIAL_STATE = {
    negoItems: [],
    companies: []
};

export const updateNegoScreenNegotiations = (negoItems = []) => ({
    type: UPDATE_NEGO_SCREEN_NEGOS,
    negoItems
});

export const updateNegoScreenData = (data) => ({
    type: UPDATE_NEGO_SCREEN_DATA,
    data
});

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_NEGO_SCREEN_NEGOS:
            return {
                ...state,
                negoItems: action.negoItems
            };
        case UPDATE_NEGO_SCREEN_DATA:
            return {
                ...state,
                ...action.data
            };

        default:
            return state;
    }
};

export default reducer;
