import React from 'react';
import PropTypes from 'prop-types';
import './checkBox.scss';

checkBoxForm.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    placeholder: PropTypes.any,
    nolabel: PropTypes.bool,
    value: PropTypes.bool,
    classname: PropTypes.string,
    showError: PropTypes.bool,
    errMsg: PropTypes.string,
    edit: PropTypes.bool,
    disabled: PropTypes.bool
};

function checkBoxForm({ label, onChange, required, placeholder, nolabel, classname, value, id, showError, errMsg, edit, disabled }) {
    return (<div className={classname ? classname + 'checkBox' : 'checkBox'} id={id + 'DIV'}>
        {edit ? <>
            <span className='checkBox__label'>
                {label}
            </span>
            <label className='checkBox__label-tag'>
                {placeholder}
                <input className='checkBox__label-tag-input' type='checkbox' checked={value}
                    disabled={disabled}
                    onChange={() => {}}/>
                <span className='checkBox__label-tag-mark' />
            </label>
        </>
            : (<>
                {!nolabel &&
                    <span className='checkBox__label'>
                        {label}
                    </span>
                }
                <label className='checkBox__label-tag'>
                    {placeholder}
                    <input className='checkBox__label-tag-input' type='checkbox' checked={value}
                        disabled={disabled}
                        onChange={e => onChange(e.target.checked)} />
                    <span className={!disabled ? 'checkBox__label-tag-mark' : 'checkBox__label-tag-mark disabled-check-box'} />
                </label>
                {showError && <span className='inputDiv__error'>{errMsg}</span>}
            </>)
        }

    </div>
    );
}

export default checkBoxForm;
