import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './NumberInput.scss';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';

NumberInput.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    step: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    min: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    precision: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    isValid: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    smallMode: PropTypes.bool,
    onlyByArrows: PropTypes.bool,
    hideArrows: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    tabIndex: PropTypes.number,
    buttonsClassName: PropTypes.string,
    errorMessage: PropTypes.any,
    placeholder: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    hasWarning: PropTypes.bool,
    canBeNegative: PropTypes.bool
};

function NumberInput({
    value,
    step,
    min,
    max,
    precision,
    isValid,
    disabled,
    className,
    onChange,
    smallMode,
    onlyByArrows,
    hideArrows,
    defaultValue,
    tabIndex,
    buttonsClassName,
    errorMessage,
    placeholder,
    id,
    name,
    hasWarning,
    canBeNegative = false
}) {
    const [input, setInput] = useState(null);
    const currentValue = typeof value === 'undefined' ? '' : value;
    const isDisabled = typeof disabled === 'undefined' ? false : disabled;
    const changeOnlyByArrows = typeof onlyByArrows === 'undefined' ? false : onlyByArrows;

    const handleStepUp = (e) => {
        e.preventDefault();
        const steppedUp = currentValue ? parseFloat(currentValue) + parseFloat(step) : parseFloat(step);

        if ((typeof max === 'undefined' || steppedUp <= parseFloat(max)) && !isDisabled && input !== null) {
            const event = new Event('input', { bubbles: true });

            setNativeValue(input, steppedUp.toString());
            input.dispatchEvent(event);
        }
    };

    function createRegex(decimalDigits, sign) {
        let signRegex = '';

        if (sign === 'positiveOnly') {
            signRegex = '';
        } else if (sign === 'negativeOnly') {
            signRegex = '-';
        } else {
            signRegex = '\\-?';
        }

        return new RegExp(`^${signRegex}\\d+(\\.\\d{0,${decimalDigits}})?$`);
    }

    const handleStepDown = (e) => {
        e.preventDefault();
        const steppedDown = currentValue ? parseFloat(currentValue) - parseFloat(step) : 0;

        if ((typeof min === 'undefined' || steppedDown >= parseFloat(min)) && !isDisabled && input !== null) {
            const event = new Event('input', { bubbles: true });

            setNativeValue(input, steppedDown.toString());
            input.dispatchEvent(event);
        }
    };

    const setNativeValue = (el, value) => {
        const valueSetter = Object.getOwnPropertyDescriptor(el, 'value').set;
        const prototype = Object.getPrototypeOf(el);
        const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

        const toPrecise = typeof precision === 'undefined' ? 0 : parseInt(precision);
        const returnedValue = value ? parseFloat(value).toFixed(toPrecise).toString() : value;

        if (valueSetter && valueSetter !== prototypeValueSetter) {
            prototypeValueSetter.call(el, returnedValue);
        } else {
            valueSetter.call(el, returnedValue);
        }
    };

    let valueManipulated = false;

    if (defaultValue !== undefined) {
        valueManipulated = defaultValue !== value;
    }

    const inputClassname = classNames([
        className,
        'number-input__field',
        `${isValid ? '' : 'number-input__field--invalid'}`,
        `${isDisabled ? 'number-input__field--disabled ' + className + '--disabled' : ''}`,
        `${isValid ? '' : className + '--invalid'}`,
        `${!hasWarning ? '' : 'number-input__field--warning'}`,
        `${valueManipulated ? className + '--manipulated' : ''}`
    ]);

    const handleChange = (e) => {
        const pattern = createRegex(precision || 0, canBeNegative ? 'both' : 'positiveOnly');

        const shouldPassIfNegativeAndSign = canBeNegative && e.target.value === '-';

        return !isDisabled && (pattern.test(e.target.value) || !e.target.value || shouldPassIfNegativeAndSign) && onChange(e);
    };

    const handleBlur = (e) => {
        const event = new Event('input', { bubbles: true });

        setNativeValue(input, e.target.value === '-' ? '' : e.target.value);
        input.dispatchEvent(event);
    };

    return (

        <Tippy allowHTML={false} placement='top' disabled={!errorMessage}
            className='pt-tooltip'
            content={errorMessage}>
            <div className='number-input'>
                <input
                    placeholder={placeholder}
                    id={id}
                    name={name}
                    type='text'
                    className={inputClassname}
                    disabled={isDisabled || changeOnlyByArrows}
                    ref={ref => setInput(ref)}
                    value={currentValue}
                    onBlur={handleBlur}
                    tabIndex={tabIndex}
                    onChange={handleChange} />
                {
                    !isDisabled && !hideArrows ? (
                        <div className={classNames('number-input__button-wrapper', buttonsClassName, { 'number-input__button-wrapper--small': smallMode })}>
                            <button
                                className='number-input__button-step number-input__button-step--up'
                                onClick={handleStepUp} />
                            <button
                                className='number-input__button-step number-input__button-step--down'
                                onClick={handleStepDown} />
                        </div>
                    ) : null
                }
            </div>
        </Tippy>

    );
}

export default NumberInput;
