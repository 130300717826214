import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ModalCancelOrderScreen from 'screens/Order/ModalCancelOrderScreen';
import { notifType, notify } from 'utils/notifSender';
import { connect } from 'react-redux';
import { cancelOrders } from 'api/order';
import { cancelAllNegotiations } from 'api/negotiation';
import { withAsyncCaller } from 'creatella-react-components/lib/HOCs/withAsyncCaller';
import { CANCEL_TYPE, ORDER_TYPE } from 'config/constants';
import { closeCancelModal } from 'redux/reducers/cancelOrder';

ModalCreateOrderContainer.propTypes = {
    handleClose: PropTypes.func.isRequired,
    apiCaller: PropTypes.func.isRequired,
    cancelType: PropTypes.number,
    isVisible: PropTypes.bool,
    companyOrders: PropTypes.bool
};

function ModalCreateOrderContainer({
    cancelType,
    isVisible,
    handleClose,
    apiCaller,
    companyOrders
}) {
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async () => {
        setIsProcessing(true);
        let isMounted = true;

        try {
            if (cancelType === CANCEL_TYPE.ALL_ORDERS) {
                const { data } = await apiCaller(cancelOrders, { type: 0, company_wide: companyOrders });

                if (data.total > 0) {
                    notify('All orders are cancelled!', notifType.SUCCESS);
                } else {
                    notify('No order to cancel!', notifType.DANGER);
                }
            }

            if (cancelType === CANCEL_TYPE.ALL_BUY) {
                const { data } = await apiCaller(cancelOrders, { type: ORDER_TYPE.BUY, company_wide: companyOrders });

                if (data.total > 0) {
                    notify('All buy orders are cancelled!', notifType.SUCCESS);
                } else {
                    notify('No order to cancel!', notifType.DANGER);
                }
            }

            if (cancelType === CANCEL_TYPE.ALL_SELL) {
                const { data } = await apiCaller(cancelOrders, { type: ORDER_TYPE.SELL, company_wide: companyOrders });

                if (data.total > 0) {
                    notify('All sell orders are cancelled!', notifType.SUCCESS);
                } else {
                    notify('No order to cancel!', notifType.DANGER);
                }
            }

            if (cancelType === CANCEL_TYPE.ALL_NEGO) {
                const { data } = await apiCaller(cancelAllNegotiations, { company_wide: companyOrders });

                if (data.total > 0) {
                    notify('All negotiations are cancelled!', notifType.SUCCESS);
                } else {
                    notify('No any negotiation records found to cancel!', notifType.DANGER);
                }
            }
        } catch (error) {
            isMounted = !!error;

            if (error) {
                notify('An error occurred!', notifType.DANGER);
            }
        } finally {
            if (isMounted) {
                handleClose();
                setIsProcessing(false);
            }
        }
    };

    const handleModalClose = () => {
        handleClose();
    };

    const getMessage = () => {
        const companyText = companyOrders ? ' company ' : '';

        switch (cancelType) {
            case CANCEL_TYPE.ALL_ORDERS:
                return `Are you sure you want to withdraw all of your ${companyText}orders on the market? This cannot be undone.`;
            case CANCEL_TYPE.ALL_BUY:
                return `Are you sure you want to withdraw all of your ${companyText}buy orders on the market? This cannot be undone.`;
            case CANCEL_TYPE.ALL_SELL:
                return `Are you sure you want to withdraw all of your ${companyText}sell orders on the market? This cannot be undone.`;
            case CANCEL_TYPE.ALL_NEGO:
                return `Are you sure you want to withdraw all of your ${companyText}negotiations? This cannot be undone.`;

            default:
                return '';
        }
    };

    return (
        isVisible
            ? <ModalCancelOrderScreen
                isVisible={isVisible}
                isProcessing={isProcessing}
                handleClose={handleModalClose}
                handleSubmit={handleSubmit}
                title='Confirmation'
                message={getMessage()}
                yesButtonText='Yes, Withdraw'
                noButtonText='No, go back' />
            : null
    );
}

const mapStateToProps = state => {
    return {
        isVisible: state.cancelOrder.shouldCancelModalShown,
        cancelType: state.cancelOrder.cancelType,
        companyOrders: state.cancelOrder.companyOrders
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleClose: () => {
            dispatch(closeCancelModal());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withAsyncCaller(ModalCreateOrderContainer));
