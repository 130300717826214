import React, { useState, useRef, useEffect } from 'react';
import './gTcCheckBox.scss';
import Checkbox from 'screens/Registration/elements/checkBox';
import infoGrey from 'assets/images/general/info-grey.svg';
import PropTypes from 'prop-types';

GTcCheckBox.propTypes = {
    isGtc: PropTypes.bool.isRequired,
    setIsGtc: PropTypes.func.isRequired,
    isNewOrder: PropTypes.bool
};

function GTcCheckBox(props) {
    const ref = useRef(null);

    const [show, setShow] = useState(false);

    useEffect(() => {
        function handleClickOutside(event) {
            setShow(false);
            // if (ref.current && !ref.current.contains(event.target)) {
            // }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return (
        <div className='gtc' ref={ref}>
            <Checkbox
                label=''
                nolabel={true}
                placeholder={<p className='gtc__desc'>GTC</p>}
                errMsg={''}
                onChange={props.setIsGtc}
                value={props.isGtc}
                id='gtcID'/>
            <div className='gtc__commision-box'>
                <img
                    onClick={() => setShow(true)}
                    className='gtc__info-icon'
                    src={infoGrey}
                    alt='error' />
            </div>
            <div className={`gtc__info-details ${show ? 'gtc__info-details--active' : ''}`} style={props.isNewOrder ? { bottom: '10px' } : { top: '40px', right: 0 }}>
                <span className='gtc__info-details--bold'>Disclaimer</span>
                Please note that by ticking this box you hereby agree that your order will remain active, and tradeable for your approved counterparties, up until the moment one of the following situations takes place:
                <br/>
                1) The order is filled, ie: a price match is completed.
                <br/>
                2) You have cancelled this order via the orderbook.
                <br/>
                3) The tenor (delivery period) has expired.
                <br/>
                This order will not be automatically cancelled at the end of the day (EOD) as per all other standard orders.
                <br/>
                If this GTC is an indicative order and potential negotiations are unsuccessful, the original order will remain active. Any negotiations will have to be restarted the next day.
                <br/>
                <br/>
                <span className='gtc__info-details--bold'>Please be aware</span>

                The platform is only offering this as a service and is not acting as an exchange. We are therefore not responsible for potential technological bugs that might accidently cause the order to be deleted for any reason.
                <br/>
                Do check your &ldquo;orderbook&ldquo; on a daily basis to confirm that all your orders are correct.
            </div>
        </div>
    );
}

export default GTcCheckBox;
