import PropTypes from 'prop-types';
import React from 'react';
import ActivityIndicator from 'creatella-react-components/lib/ActivityIndicator';
import './AppLoading.scss';
import logoLoader from 'assets/images/general/pt-loader.gif';

AppLoading.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
    showLogo: PropTypes.bool
};

AppLoading.defaultProps = {
    className: '',
    size: 50,
    showLogo: false
};

export default function AppLoading({ className, size, showLogo }) {
    return (
        <div className={`AppLoading ${className}`}>
            {showLogo ? <img className='AppLoading__loader' src={logoLoader} alt='loader'/> : <ActivityIndicator size={size} />}
        </div>
    );
}
