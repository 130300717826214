export const UPDATE_ORDER_BOOK_ORDERS = 'UPDATE_ORDER_BOOK_ORDERS';
export const UPDATE_ORDER_BOOK_DATA = 'UPDATE_ORDER_BOOK_DATA';

const INITIAL_STATE = {
    orders: [],
    companies: [],
    counterparties: [],
    brokers: [],
    traders: []
};

export const updateOrderBookOrders = (orders = []) => ({
    type: UPDATE_ORDER_BOOK_ORDERS,
    orders: orders
});

export const updateOrderBookData = (data) => ({
    type: UPDATE_ORDER_BOOK_DATA,
    data
});

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_ORDER_BOOK_ORDERS:
            return {
                ...state,
                orders: action.orders
            };
        case UPDATE_ORDER_BOOK_DATA:
            return {
                ...state,
                ...action.data
            };

        default:
            return state;
    }
};

export default reducer;
