export const UPDATE_INDICATIONS_SCREEN_PRODUCTS = 'UPDATE_INDICATIONS_SCREEN_PRODUCTS';
export const UPDATE_INDICATIONS_SCREEN_BROKERAGES = 'UPDATE_INDICATIONS_SCREEN_BROKERAGES';
export const UPDATE_INDICATIONS_SCREEN_CLIENTS = 'UPDATE_INDICATIONS_SCREEN_CLIENTS';

const INITIAL_STATE = {
    products: [], // for trader & broker sides
    brokerages: [], // for trader side
    clients: [], // for broker side
    allTraders: [] // for broker side

};

export const updateIndicationsScreenProducts = (products) => ({
    type: UPDATE_INDICATIONS_SCREEN_PRODUCTS,
    products
});

export const updateIndicationsScreenBrokerages = (brokerages) => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_INDICATIONS_SCREEN_BROKERAGES,
            brokerages
        });
    };
};

export const updateIndicationsScreenClients = (clients, allTraders) => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_INDICATIONS_SCREEN_CLIENTS,
            clients,
            allTraders
        });
    };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_INDICATIONS_SCREEN_PRODUCTS:
            return {
                ...state,
                products: action.products
            };
        case UPDATE_INDICATIONS_SCREEN_BROKERAGES:
            return {
                ...state,
                brokerages: action.brokerages
            };

        case UPDATE_INDICATIONS_SCREEN_CLIENTS:
            return {
                ...state,
                clients: action.clients,
                allTraders: action.allTraders
            };

        default:
            return state;
    }
};
