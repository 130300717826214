export const UPDATE_QUOTE_SCREEN = 'UPDATE_QUOTE_SCREEN';

const INITIAL_STATE = {
    productTypes: []
};

export const updateQuoteScreen = (productTypes) => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_QUOTE_SCREEN,
            productTypes
        });
    };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_QUOTE_SCREEN:
            return {
                ...state,
                productTypes: action.productTypes
            };

        default:
            return state;
    }
};
