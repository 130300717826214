import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'containers/App/components/Modal/Modal';
import AppLoadingOverlay from 'containers/App/components/Loading/AppLoadingOverlay';
import Button from 'creatella-react-components/lib/Button';
import './ModalConfirm.scss';
import classNames from 'classnames';

export const CONFIRM_MODAL_DEFAULT_STATE = {
    type: null,
    isVisible: false,
    handleClose: null,
    handleSubmit: null,
    isProcessing: false,
    title: '',
    wide: false,
    superWide: false,
    multiLine: false
};

ConfirmModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    yesButtonText: PropTypes.string,
    noButtonText: PropTypes.string,
    noButtonColor: PropTypes.string,
    isVisible: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    isProcessing: PropTypes.bool,
    yesButtonColor: PropTypes.string,
    wide: PropTypes.bool,
    superWide: PropTypes.bool,
    multiLine: PropTypes.bool,
    controlsLayout: PropTypes.string
};

function ConfirmModal({
    isVisible,
    isProcessing,
    handleClose,
    handleSubmit,
    title,
    message,
    yesButtonText,
    noButtonText,
    noButtonColor = 'white',
    yesButtonColor = 'green',
    controlsLayout = 'column',
    wide = false,
    superWide = false,
    multiLine = false
}) {
    return (
        <Modal
            title={title}
            isVisible={isVisible}
            onClose={handleClose}
            className='modal-confirm-screen'>
            <div className='confirm-modal'>
                <div className={classNames('confirm-modal__message', { ' confirm-modal__message--wide': wide, ' confirm-modal__message--superwide': superWide, 'confirm-modal__message--multiline': multiLine })}>{message}</div>
                <div className={`confirm-modal__button-container__${controlsLayout}`}>
                    {yesButtonText ? <Button onClick={handleSubmit} className={classNames(`confirm-modal__submit-button confirm-modal__submit-button--${yesButtonColor}`, { 'confirm-modal__submit-button--wide': wide })}>{yesButtonText}</Button> : null}

                    {noButtonText ? <Button onClick={handleClose} className={classNames(`confirm-modal__submit-button confirm-modal__submit-button--${noButtonColor}`, { 'confirm-modal__submit-button--wide': wide, 'confirm-modal__submit-button--superwide': superWide })}>{noButtonText}</Button> : null}
                </div>
            </div>
            {isProcessing && <AppLoadingOverlay />}
        </Modal>
    );
}

export default ConfirmModal;
