import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import './CancelMenu.scss';
import { useOnClickOutside } from 'utils/helpers';
import { CANCEL_MENU_STATUS, CANCEL_TYPE, ROUTES } from 'config/constants';
import classNames from 'classnames';
import { useHistory } from 'react-router';

CancelMenu.propTypes = {
    cancelMenuStatus: PropTypes.number.isRequired,
    setCancelMenuStatus: PropTypes.func.isRequired,
    cancelButtonRef: PropTypes.oneOfType([
        // Either a function
        PropTypes.func,
        // Or the instance of a DOM native element (see the note about SSR)
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    showCancelModal: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    cancelCompanyOrders: PropTypes.bool.isRequired,
    setCancelCompanyOrders: PropTypes.func.isRequired,
    closeHamburgerMenu: PropTypes.func.isRequired,
    updateBackdrop: PropTypes.func.isRequired
};

function CancelMenu({
    cancelMenuStatus,
    setCancelMenuStatus,
    cancelButtonRef,
    showCancelModal,
    profile,
    cancelCompanyOrders,
    setCancelCompanyOrders,
    closeHamburgerMenu,
    updateBackdrop
}) {
    const isVisible = cancelMenuStatus !== CANCEL_MENU_STATUS.HIDDEN;
    const cancelMenuRef = useRef();
    const history = useHistory();

    useOnClickOutside(cancelMenuRef, useCallback(() => {
        setCancelMenuStatus(CANCEL_MENU_STATUS.HIDDEN);
        setCancelCompanyOrders(false);
        updateBackdrop(false);
    }, [setCancelCompanyOrders, setCancelMenuStatus, updateBackdrop]), cancelButtonRef);

    const onClickItem = (type, companyOrders) => () => {
        setCancelMenuStatus(CANCEL_MENU_STATUS.HIDDEN);

        if (type === CANCEL_TYPE.SINGLE) {
            history.replace(ROUTES.ORDER_BOOK);
        } else {
            showCancelModal(type, companyOrders);
        }

        closeHamburgerMenu();
        updateBackdrop(false);
    };

    let companyName = '';

    if (profile.is_trader) {
        companyName = profile.trader.company.name;
    }

    if (profile.is_middle_office) {
        companyName = profile.middle_office.company.name;
    }

    return (
        !isVisible ? false
            : <div className={'cancel-menu'}>
                <div ref={cancelMenuRef} className={'cancel-menu__container'}>
                    <div className='cancel-menu__pointer' />
                    <div className={'cancel-menu__wrapper'}>
                        <div className={'cancel-menu__body'} >
                            {profile.is_trader && profile.trader.is_tm ? <div className={'cancel-menu__selector-row'}>
                                <div className={classNames('cancel-menu__switch-button', { 'cancel-menu__switch-button--active': !cancelCompanyOrders })} onClick={() => setCancelCompanyOrders(false)}>My Orders</div>
                                <div className={classNames('cancel-menu__switch-button', 'cancel-menu__switch-button--second', { 'cancel-menu__switch-button--active': cancelCompanyOrders })} onClick={() => setCancelCompanyOrders(true)}>Company Orders</div>
                            </div> : null}
                            <div className={'cancel-menu__separator'} />

                            {
                                !cancelCompanyOrders ? <><div className={'cancel-menu__row'} onClick={onClickItem(CANCEL_TYPE.SINGLE)}>Single Order</div>
                                    <div className={'cancel-menu__separator'} />
                                </> : null}

                            <div className={'cancel-menu__row'} onClick={onClickItem(CANCEL_TYPE.ALL_ORDERS, cancelCompanyOrders)}>{cancelCompanyOrders ? `${companyName} - All Orders` : 'All Orders'}</div>
                            <div className={'cancel-menu__separator'} />
                            <div className={'cancel-menu__row'} onClick={onClickItem(CANCEL_TYPE.ALL_SELL, cancelCompanyOrders)}>{cancelCompanyOrders ? `${companyName} - All Sell Orders` : 'All Sell Orders'}</div>
                            <div className={'cancel-menu__separator'} />
                            <div className={'cancel-menu__row'} onClick={onClickItem(CANCEL_TYPE.ALL_BUY, cancelCompanyOrders)}>{cancelCompanyOrders ? `${companyName} - All Buy Orders` : 'All Buy Orders'}</div>
                            <div className={'cancel-menu__separator'} />
                            <div className={'cancel-menu__row'} onClick={onClickItem(CANCEL_TYPE.ALL_NEGO, cancelCompanyOrders)}>{cancelCompanyOrders ? `${companyName} - All Negotiations` : 'My Negotiations'}</div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default (CancelMenu);
