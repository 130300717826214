import { useQuery } from '@tanstack/react-query';
import { TRADING_ELOQUENT as ELOQUENT } from 'config/constants';
import { queryClientKeys } from 'config/executors/apiInterceptor';

/**
 * Get all companies and their traders
 * @param {Object} data
 * @returns {Promise}
 */
export function getAllClients(data) {
    return ELOQUENT.MyClients.index(data);
}

export const useAllClientsApi = (apiCaller, data) => {
    const query = useQuery({
        queryKey: [queryClientKeys.useAllClientsApi, data],
        queryFn: async ee => await apiCaller(getAllClients, data)
    });

    return { ...query, data: query.data?.data?.data ?? [] };
};

/**
 * activate a company for bad
 * @param {Number} companyId
 * @returns {Promise}
 */
export function activateCompanyForBAD(companyId, { token }) {
    return ELOQUENT.MyClients.activate(companyId, token);
}

/**
 * deactivate a company for bad
 * @param {Number} companyId
 * @returns {Promise}
 */
export function deactivateCompanyForBAD(companyId, { token }) {
    return ELOQUENT.MyClients.deactivate(companyId, token);
}

/**
 * Get all unassigned companies
 * @returns {Promise}
 */
export function getUnassignedCompanies({ token }) {
    return ELOQUENT.MyClients.unassigned(null, token);
}

/**
 * add a new company and assign brokers for bad
 * @param {Object} data
 * @returns {Promise}
 */
export function addCompanyAndAssignBrokers(data, { token }) {
    return ELOQUENT.MyClients.create(data, token);
}

/**
 * update company and products brokers
 * @param {Number} companyId
 * @param {Object} data
 * @returns {Promise}
 */
export function updateCompanyAndProductsForBAD(companyId, data, { token }) {
    return ELOQUENT.MyClients.update(companyId, data, token);
}

/**
 * Get a company for bad
 * @param {Number} companyId
 * @returns {Promise}
 */
export function getACompanyForBAD(companyId, { token }) {
    return ELOQUENT.MyClients.show(companyId, token);
}

/**
 * approve a company for bad
 * @param {Number} companyId
 * @returns {Promise}
 */
export function approveCompanyForBAD(companyId, { token }) {
    return ELOQUENT.MyClients.approve(companyId, token);
}
