import React from 'react';
import AppLoading from './AppLoading';
import './AppLoadingOverlay.scss';
import PropTypes from 'prop-types';

AppLoadingOverlay.propTypes = {
    className: PropTypes.string,
    showLogo: PropTypes.bool
};

AppLoadingOverlay.defaultProps = {
    className: '',
    showLogo: false
};

export default function AppLoadingOverlay({ className, showLogo }) {
    return (
        <div className='overlay'>
            <AppLoading showLogo={showLogo} className={className} />
        </div>
    );
}
