import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './Switch.scss';

Switch.propTypes = {
    value: PropTypes.bool,
    disabled: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func
    ]),
    onChange: PropTypes.func,
    grayOnOff: PropTypes.bool
};

function Switch({ value, disabled, onChange, grayOnOff }) {
    const currentValue = typeof value === 'undefined' ? false : value;
    const isDisabled = typeof disabled === 'undefined' ? false : disabled;

    const switchWrapperClass = classNames(
        'switch__wrapper',
        `switch__wrapper--${isDisabled ? 'disabled' : 'enabled'}`,
        `switch__wrapper--${!currentValue && grayOnOff ? 'off-gray' : 'on'}`
    );

    const switchTogglerClass = classNames(
        'switch__toggler',
        `switch__toggler--${!currentValue ? 'off' : 'on'}`,
        `switch__toggler--${isDisabled ? 'disabled' : 'enabled'}`
    );

    return (
        <div className={switchWrapperClass} onClick={() => onChange(!currentValue)}>
            <div className={switchTogglerClass} />
        </div>
    );
}

export default Switch;
