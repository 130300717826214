/* eslint-disable react/prop-types */
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import useComponentSubscriber from 'hooks/useComponentSubscriber';

const useModalProvider = () => {
    const { setSubscriber: setModalListSubscriber, notifySubscribers: notifyModalListSubscriber } = useComponentSubscriber();

    const addNewModal = useCallback((newModalElement) => notifyModalListSubscriber(newModalElement), [notifyModalListSubscriber]);

    return { setModalListSubscriber, addNewModal };
};

const ModalProviderContext = createContext();

export const useModalPortal = () => {
    const context = useContext(ModalProviderContext);

    return { addNewModal: context.addNewModal };
};

export const ModalsRenderer = () => {
    const context = useContext(ModalProviderContext);
    const [elements, setElements] = useState([]);

    useEffect(() => {
        return context.setModalListSubscriber((newModalElement) => {
            // console.log('setModalListSubscriber');
            setElements(e => {
                e.push(newModalElement[0]);

                return [...e];
            });
        });
    }, [context]);

    const mapElements = useCallback((elem, key) => {
        // console.log('elem(key)', elem(key));

        return elem(key);
    }, []);

    // console.log('elements', elements);

    return (
        <div>
            {elements.map(mapElements)}
        </div>
    );
};

function ModalProvider({ children }) {
    return (
        <ModalProviderContext.Provider value={useModalProvider()}>
            {children}
            <ModalsRenderer />
        </ModalProviderContext.Provider>
    );
}

export default ModalProvider;
