/* eslint-disable react/prop-types */
import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'config/executors/apiInterceptor';

export default function QueryProvider({ children }) {
    return (
        <QueryClientProvider client={queryClient}>{ children }</QueryClientProvider>
    );
}
