import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import EventEmitter from 'utils/EventEmitter';
import { NOTIFICATION_TYPE, USER_TYPES, NUDGE_SECS } from 'config/constants';
import { differenceInSeconds, parseISO } from 'date-fns';
import { updateNonAcknowledgedNegotiationsNudges } from 'redux/reducers/auth';
import classNames from 'classnames';
import './Nudge.scss';

Nudge.propTypes = {
    profile: PropTypes.object.isRequired,
    updateUserNudges: PropTypes.func.isRequired,
    broker: PropTypes.object,
    trader: PropTypes.object
};

function Nudge({ profile, updateUserNudges, broker, trader }) {
    const nudges = useMemo(() => {
        return !profile.is_broker
            ? !profile.is_trader
                ? []
                : profile.trader.not_acknowledged_negotiation_nudges
            : profile.broker.not_acknowledged_negotiation_nudges;
    }, [profile.broker?.not_acknowledged_negotiation_nudges, profile.is_broker, profile.is_trader, profile.trader?.not_acknowledged_negotiation_nudges]);

    const sortByUpdatedAtDesc = (a, b) => parseISO(b.updated_at) - parseISO(a.updated_at);
    const recentNudge = nudges.sort(sortByUpdatedAtDesc)[0];
    const recentNudgeTimeDiff = recentNudge ? differenceInSeconds(new Date(), parseISO(recentNudge.updated_at)) : (NUDGE_SECS + 1);
    const [shouldPulse, setShouldPulse] = useState(recentNudgeTimeDiff <= NUDGE_SECS);

    if (shouldPulse) {
        const recentNudgeTimer = setTimeout(() => {
            setShouldPulse(false);

            clearTimeout(recentNudgeTimer);
        }, (NUDGE_SECS - recentNudgeTimeDiff) * 1000);
    }

    const myId = !profile.is_broker ? !profile.is_trader ? null : profile.trader.id : profile.broker.id;
    const myType = !profile.is_broker ? !profile.is_trader ? null : USER_TYPES.TRADER : USER_TYPES.BROKER;
    const myUserType = !profile.is_broker ? !profile.is_trader ? null : 'trader' : 'broker';

    const isNudgeSimilar = (nudge2) => (nudge) => {
        return nudge.recipient_type === nudge2.recipient_type &&
            nudge.recipient_id === nudge2.recipient_id &&
            nudge.sender_type === nudge2.sender_type &&
            nudge.sender_id === nudge2.sender_id &&
            nudge.source_type === nudge2.source_type &&
            nudge.source_id === nudge2.source_id;
    };

    const handleNegotiationNudged = useCallback(
        (data) => {
            if (data.recipient_id === myId && data.recipient_type === myType) {
                const found = nudges.find(isNudgeSimilar(data));

                if (!found || parseISO(found.updated_at) <= parseISO(data.updated_at)) {
                    const newNudges = found ? nudges.map(nudge => isNudgeSimilar(found)(nudge) ? data : nudge) : [...nudges, data];

                    updateUserNudges(newNudges, myUserType);
                }

                const recentNudgeTimeDiff = differenceInSeconds(new Date(), parseISO(data.updated_at));

                setShouldPulse(recentNudgeTimeDiff <= NUDGE_SECS);

                if (
                    (broker && data.recipient_type === 'App\\Models\\Broker' && data.recipient_id === broker.id) ||
                    (trader && data.recipient_type === 'App\\Models\\Trader' && data.recipient_id === trader.id)
                ) {
                    EventEmitter.emit(NOTIFICATION_TYPE.USER_NUDGE_PLAY_SOUND);
                }
            }
        }, [broker, myId, myType, myUserType, nudges, trader, updateUserNudges]
    );

    const handleNegotiationNudgeAcknowledge = useCallback(
        (data) => {
            const nudgesForMe = profile.is_trader ? data.nudges_for_client : data.nudges_for_broker;
            const latestNudge = nudgesForMe.sort(sortByUpdatedAtDesc).find(nudge => nudge.recipient_id === myId && nudge.recipient_type === myType);
            const found = latestNudge ? nudges.find(isNudgeSimilar(latestNudge)) : null;

            if (found && parseISO(found.updated_at) <= parseISO(latestNudge.updated_at)) {
                const newNudges = nudges.filter(nudge => !isNudgeSimilar(found)(nudge));

                updateUserNudges(newNudges, myUserType);
            }
        }, [myId, myType, myUserType, nudges, profile.is_trader, updateUserNudges]
    );

    const handleNewNotification = useCallback(
        (event) => {
            if (event.type === NOTIFICATION_TYPE.USER_NUDGE) {
                handleNegotiationNudged(event.data);
            } else if (event.type === NOTIFICATION_TYPE.USER_NUDGE_ACKNOWLEDGED) {
                handleNegotiationNudgeAcknowledge(event.data);
            }
        }, [handleNegotiationNudgeAcknowledge, handleNegotiationNudged]
    );

    useEffect(() => {
        const newNotificationListener = EventEmitter.addListener('NewNotificationReceived', handleNewNotification);

        return () => {
            // cleaning up the listeners here
            newNotificationListener.remove();
        };
    }, [handleNewNotification]);

    return nudges.length > 0 && <div className='header-nudge'>
        <div className={classNames('header-nudge__indicator', {
            ' header-nudge__indicator--pulsing': shouldPulse
        })} />
    </div>;
}

const mapStateToProps = ({ auth }) => {
    const { profile } = auth;

    return {
        profile,
        broker: profile.broker,
        trader: profile.trader
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateUserNudges: (nudges, type) => {
        dispatch(updateNonAcknowledgedNegotiationsNudges(nudges, type));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Nudge);
