import { AUTH_ELOQUENT, AUTH_ELOQUENT as ELOQUENT, TRADING_ELOQUENT } from 'config/constants';

/**
 * Register user
 * ERRORS:
 * * 422 - email already exists
 * @param {Object} data
 * @returns {Promise}
 */
export function register(data) {
    return ELOQUENT.Auth.register(data);
}

/**
 * Login user with email and password
 * ERRORS:
 * * 401 - wrong email or password
 * * 422 - email not verified
 * * 428, Need another confirmation
 * @param {String} email
 * @param {String} password
 * @param {String} deviceId
 * @returns {Promise}
 */
export function login(email, password, deviceId) {
    return ELOQUENT.Auth.login(email, password, deviceId);
    // return ELOQUENT.Auth.login(email, password);
}

/**
 * Logout user
 * @returns {Promise}
 */
export function logout() {
    return ELOQUENT.Auth.logout();
}

/**
 * request a password reset code by the given email
 * ERRORS:
 * * 404 - email not found
 * @param {String} email
 * @returns {Promise}
 */
export function requestPasswordReset(email) {
    return ELOQUENT.Auth.forgotPassword(email);
}

/**
 * reset the user's password
 * ERRORS:
 * * 404 - code not found
 * * 422 - code expired
 * @param {String} token
 * @param {String} email
 * @param {String} password
 * @param {String} password_confirmation
 * @param {String} pin
 * @param {String} pin_confirmation
 * @param {String} otp
 * @returns {Promise}
 */
// eslint-disable-next-line camelcase
export function resetPassword(token, email, password, password_confirmation, pin, pin_confirmation, otp) {
    return ELOQUENT.Auth.resetPassword(token, email, password, password_confirmation, pin, pin_confirmation, otp);
}

/**
 * Sets the the authorization token for api requests
 * @param {String} token
 */
export function setApiRequestToken(token) {
    TRADING_ELOQUENT && TRADING_ELOQUENT.setApiRequestToken(token);
    AUTH_ELOQUENT && AUTH_ELOQUENT.setApiRequestToken(token);
}

/**
 * Unsets the the authorization token for api requests
 */
export function unsetApiRequestToken() {
    TRADING_ELOQUENT && TRADING_ELOQUENT.unsetApiRequestToken();
    AUTH_ELOQUENT && AUTH_ELOQUENT.unsetApiRequestToken();
}

/**
 * Lock user

 * @returns {Promise}
 */
export function lockUser({ token }) {
    return ELOQUENT.User.lock(token);
}

/**
 * Unlock user
 * @param {Number} pin
 * @returns {Promise}
 */
export function unlockUser(pin, { token }) {
    return ELOQUENT.User.unlock({ pin }, token);
}

/**
 * request-otp-for-token
 * @param {String} urlToken
 * @param {String} email
 * @returns {Promise}
 */
export function requestOtp(urlToken, email, { token }) {
    return AUTH_ELOQUENT.Auth.requestOtpForToken({ token: urlToken, email }, token);
}

/**
 * reset pin and password
 * @returns {Promise}
 */
export function resetPinPassword({ token }) {
    return AUTH_ELOQUENT.User.reset(token);
}

/**
 * exchange Otp
 * @param {String} otp
 * @returns {Promise}
 */
export function exchangeOtp(otp, { token }) {
    return AUTH_ELOQUENT.Auth.exchangeOtpRequiredToken({ otp }, token);
}

/**
 * request new Otp
 * @returns {Promise}
 */
export function requestNewOtp({ token }) {
    return AUTH_ELOQUENT.Auth.requestOtp(token);
}

/**
 * resend Authorization Code
 * @returns {Promise}
 */
export function resendAuthorizationEmail({ token }) {
    return AUTH_ELOQUENT.Auth.resendAuthorizationCode(token);
}

/**
 * exchange Otp
 * @param {String} deviceId
 * @param {String} urlToken
 * @returns {Promise}
 */
export function exchangeAuthorization(deviceId, urlToken, { token }) {
    return AUTH_ELOQUENT.Auth.exchangeAuthorizationCode({ device_id: deviceId, token: urlToken }, token);
}

/**
 * exchange Otp
 * @param {Object} data
 * @returns {Promise}
 */
export function demoRegisteration(data, { token }) {
    return TRADING_ELOQUENT.Registration.store(data, token);
}
