import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import './ProfileMenu.scss';
import { useOnClickOutside } from 'utils/helpers';
import { PROFILE_MENU_STATUS, ROUTES, USER_ROLES } from 'config/constants';
import logout from 'assets/images/header/logout.svg';
import gearIcon from 'assets/images/header/gear.svg';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleLogout } from 'redux/reducers/auth';
import lockIcon from 'assets/images/general/lock.svg';

ProfileMenu.propTypes = {
    doLogout: PropTypes.func.isRequired,
    profileMenuStatus: PropTypes.number.isRequired,
    setProfileMenuStatus: PropTypes.func.isRequired,
    profileLinkRef: PropTypes.oneOfType([
        // Either a function
        PropTypes.func,
        // Or the instance of a DOM native element (see the note about SSR)
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    onLockClick: PropTypes.func.isRequired,
    updateBackdrop: PropTypes.func.isRequired,
    role: PropTypes.number.isRequired
};

function ProfileMenu({
    doLogout,
    profileMenuStatus,
    setProfileMenuStatus,
    profileLinkRef,
    onLockClick,
    updateBackdrop,
    role
}) {
    const isVisible = profileMenuStatus !== PROFILE_MENU_STATUS.HIDDEN;
    const profileMenuRef = useRef();
    const history = useHistory();

    useOnClickOutside(profileMenuRef, useCallback(() => {
        setProfileMenuStatus(PROFILE_MENU_STATUS.HIDDEN);
        updateBackdrop(false);
    }, [setProfileMenuStatus, updateBackdrop]), profileLinkRef);

    const onLogoutClick = () => {
        doLogout();
    };

    const onSettingsClick = () => {
        history.replace(role !== USER_ROLES.DATA_USER ? ROUTES.SETTINGS_NOTIFICATIONS : ROUTES.SETTINGS_DATAUSER_PROFILE);
        setProfileMenuStatus(PROFILE_MENU_STATUS.HIDDEN);
        updateBackdrop(false);
    };

    const onLockButtonClick = () => {
        onLockClick();
        setProfileMenuStatus(PROFILE_MENU_STATUS.HIDDEN);
        updateBackdrop(false);
    };

    return (
        !isVisible ? false
            : <div className={'profile-menu'}>
                <div ref={profileMenuRef} className={'profile-menu__container'}>
                    <div className='profile-menu__pointer' />
                    <div className={'profile-menu__wrapper'}>
                        <div className={'profile-menu__body'} >
                            <div className={'profile-menu__row'} onClick={onSettingsClick}><img alt='settings' src={gearIcon}/> Settings</div>
                            <div className={'profile-menu__row'} onClick={onLockButtonClick}><img alt='settings' src={lockIcon}/> Lock Screen</div>
                            <div className={'profile-menu__row'} onClick={onLogoutClick}><img alt='logout' src={logout}/> Logout</div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        doLogout: () => {
            dispatch(handleLogout());
        }
    };
}

export default connect(null, mapDispatchToProps)(ProfileMenu);
