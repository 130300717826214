import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'containers/App/components/Modal/Modal';
import AppLoadingOverlay from 'containers/App/components/Loading/AppLoadingOverlay';
import CheckIcon from 'assets/images/general/circle-check-icon.svg';
import WarningIcon from 'assets/images/general/warning.svg';

import classNames from 'classnames';

import './OrderCreatedMessageScreen.scss';
import { USER_ROLES } from 'config/constants';
import Button from 'creatella-react-components/lib/Button';

OrderCreatedMessageScreen.propTypes = {
    isVisible: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    isProcessing: PropTypes.bool,
    roleType: PropTypes.number.isRequired,
    orderSummery: PropTypes.object.isRequired,
    brokeragesList: PropTypes.array,
    submitResult: PropTypes.object.isRequired,
    handleGotoIndications: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired
};

function OrderCreatedMessageScreen({
    isVisible,
    isProcessing,
    handleClose,
    roleType,
    orderSummery,
    brokeragesList,
    submitResult,
    handleGotoIndications,
    profile
}) {
    const brokerages = orderSummery.brokerages?.map(bId => ({
        id: bId,
        name: brokeragesList.find(brokerage => brokerage.id === bId).name
    }));

    return (
        <Modal
            className='modal-created-order-screen'
            onClose={handleClose}
            isVisible={isVisible}
            title=''>
            {submitResult.skipped.length === 0 ? <div className='created-order-screen'>
                <img src={CheckIcon} className='created-order-screen__icon' alt='Success' />
                <div className='created-order-screen__message'>Order created successfully</div>
                <div className='created-order-screen__name'>{orderSummery.name}</div>

                {roleType === USER_ROLES.TRADER ? <div className='created-order-screen__orders' >
                    {brokerages.map(b => <div key={b.id}>
                        <span className={classNames('created-order-screen__order-type', { 'created-order-screen__order-type--buy': orderSummery.type === 1, 'created-order-screen__order-type--sell': orderSummery.type === 2 })}>
                            {orderSummery.type === 1 ? 'Buy' : 'Sell'}
                        </span> @ {parseFloat(orderSummery.price).toFixed(2)} {orderSummery.currency} / {orderSummery.volume ? Number(orderSummery.volume).toLocaleString() : 'No Qty'} {orderSummery.units?.toUpperCase()} <span className='created-order-screen__broker-name'>{b.name}</span>

                    </div>)}
                </div> : null}
                <Button
                    onClick={handleClose}
                    className='created-order-screen__button created-order-screen__button--close'>
                                Close
                </Button>
            </div>
                : <div>
                    {submitResult.created.length > 0
                        ? <div className='created-order-screen'>
                            <img src={CheckIcon} className='created-order-screen__icon' alt='Success' />
                            <div className='created-order-screen__message'>{submitResult.created.length} order{submitResult.created.length > 1 ? 's' : ''} created successfully</div>
                            <div className='created-order-screen__name'>{orderSummery.name}</div>

                            {roleType === USER_ROLES.TRADER ? <div className='created-order-screen__orders' >
                                {submitResult.created.map(b => <div key={b.id}>
                                    <span className={classNames('created-order-screen__order-type', { 'created-order-screen__order-type--buy': orderSummery.type === 1, 'created-order-screen__order-type--sell': orderSummery.type === 2 })}>
                                        {orderSummery.type === 1 ? 'Buy' : 'Sell'}
                                    </span> @ {parseFloat(orderSummery.price).toFixed(2)} {orderSummery.currency} / {orderSummery.volume ? Number(orderSummery.volume).toLocaleString() : 'No Qty'} {orderSummery.units?.toUpperCase()} <span className='created-order-screen__broker-name'>{b.name}</span>

                                </div>)}
                            </div> : null}
                        </div> : null }

                    <div className={classNames('created-order-screen', { 'created-order-screen--top-border': submitResult.created.length > 0 })}>
                        <img src={WarningIcon} className='created-order-screen__icon' alt='Success' />
                        <div className='created-order-screen__message'>{submitResult.skipped.length} order{submitResult.skipped.length > 1 ? 's' : ''} were unsuccessful</div>
                        {profile.is_trader ? <div className='created-order-screen__description'>
                            You already have some indicative orders for the following product, tenor and brokerages in
                            My Indications:
                        </div> : null}
                        { profile.is_broker ? <div className='created-order-screen__description'>
                            This trader has existing indications for this product and tenor which you are not permitted to edit:
                        </div> : null }

                        {profile.is_trader || profile.is_broker ? <div className='created-order-screen__orders' >
                            {submitResult.skipped.map(b => <div key={b.id}>
                                <span className={classNames('created-order-screen__order-type', { 'created-order-screen__order-type--buy': orderSummery.type === 1, 'created-order-screen__order-type--sell': orderSummery.type === 2 })}>
                                    {orderSummery.type === 1 ? 'Buy' : 'Sell'}
                                </span> @ {parseFloat(orderSummery.price).toFixed(2)} {orderSummery.currency} / {orderSummery.volume ? Number(orderSummery.volume).toLocaleString() : 'No Qty'} {orderSummery.units?.toUpperCase()} <span className='created-order-screen__broker-name'>{b.name}</span>

                            </div>)}
                        </div> : null}

                        {profile.is_trader ? <div className='created-order-screen__description'>
                            Please amend them here:
                        </div> : null}

                        {profile.is_trader ? <Button
                            onClick={handleGotoIndications}
                            className='created-order-screen__button created-order-screen__button--indications'>
                                Go to My Indications
                        </Button> : null}

                        {profile.is_trader || profile.is_broker ? <Button
                            onClick={handleClose}
                            className='created-order-screen__button created-order-screen__button--indications'>
                                Close
                        </Button> : null}

                    </div>
                </div>
            }
            {isProcessing && <AppLoadingOverlay />}
        </Modal>
    );
}

export default OrderCreatedMessageScreen;
