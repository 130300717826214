/* eslint-disable valid-jsdoc */
/* eslint-disable camelcase */
import { useMutation, useQuery } from '@tanstack/react-query';
import { TRADING_ELOQUENT as ELOQUENT } from 'config/constants';
import { queryClientKeys } from 'config/executors/apiInterceptor';

/**
 * Get order by id
 * @param {Any} id
 * @returns {Promise}
 */
export function getOrderById(id) {
    return ELOQUENT.Orders.show(id);
}

/**
 * Update order
 * @param {Any} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateOrder(id, data) {
    return ELOQUENT.Orders.update(id, data);
}

/**
 * Cancel order
 * @param {Any} id
 * @returns {Promise}
 */
export function cancelOrder(id) {
    return ELOQUENT.Orders.cancel(id);
}

export const useCancelOrderApi = (apiCaller) => {
    return useMutation({
        mutationFn: async (id) => await apiCaller(cancelOrder, id)
    });
};

/**
 * Get a product orders
 * @param {Any} product_id
 * @param {Any} type
 * @returns {Promise}
 */
export function getProductOrders(product_id, type = null, { token }) {
    return ELOQUENT.Orders.index({ product_id, type, per_page: 100 }, token);
}

/**
 * Get a product orders
 * @param {Object} data
 * @returns {Promise}
 */
export function createOrder(data) {
    return ELOQUENT.Orders.create(data);
}

/**
 * Get order logs
 * @param {Any} id
 * @returns {Promise}
 */
export function getLogs(id) {
    return ELOQUENT.Orders.logs(id);
}

/**
 * Cancel active orders
 * @param {Any} data
 * @returns {Promise}
 */
export function cancelOrders(data, { token }) {
    return ELOQUENT.BulkOrders.cancel(data, token);
}

/**
 * submit reorders
 * @param {Object} data
 * @returns {Promise}
 */
export function submitOrderBookReorders(data) {
    return ELOQUENT.Orders.regenerate(data);
}

/**
 * Secondary Clients
 * @param {Number} orderId
 * @returns {Promise}
 */
export function secondaryClients(orderId) {
    return ELOQUENT.Orders.secondaryClients(orderId);
}

/**
 * Secondary Clients Query
 * @param {Function} apiCaller
 * @param {Number} orderId
 * @returns {import('@tanstack/react-query').UseQueryResult<any, unknown>}
 */
export const useSecondaryClientssApi = (apiCaller, orderId) => {
    const query = useQuery({
        queryKey: [queryClientKeys.useSecondaryClientssApi, { orderId }],
        queryFn: async (ee) => {
            const orderId = ee.queryKey[1].orderId;

            return await apiCaller(secondaryClients, orderId);
        }
    });

    return { ...query, data: query.data?.data ?? [] };
};

/**
 * Secondary Clients
 * @param {Number} orderId
 * @param {Object} data
 * @returns {Promise}
 */
export function bookFuture(orderId, data, { token }) {
    return ELOQUENT.Orders.bookFuture(orderId, data, token);
}
