import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './Notification.scss';
import classNames from 'classnames';
import CloseIcon from 'assets/images/general/circle-close.svg';
import NotifIcon from 'assets/images/general/pt-logo-notifs.svg';
import { useOnClickOutside, usePrevious } from 'utils/helpers';
import { NOTIFICATION_TYPE, NOTIF_MODAL_STATUS } from 'config/constants';
import { withAsyncCaller } from 'creatella-react-components/lib/HOCs/withAsyncCaller';
import { getNotifications, readNotification, readNotifications } from 'api/notification';
import { notifType, notify } from 'utils/notifSender';
import AppLoading from '../Loading/AppLoading';
import { formatRelative, parseISO } from 'date-fns';
import EventEmitter from 'utils/EventEmitter';
import useSound from 'use-sound';
import notifSound from 'assets/audio/juntos-607.mp3';
import nudgeSound from 'assets/audio/dwarf-498.mp3';
import { connect } from 'react-redux';
import { updateProfileSettings } from 'redux/reducers/auth';
import InfiniteScroll from 'react-infinite-scroll-component';

Notification.propTypes = {
    notifModalStatus: PropTypes.number.isRequired,
    setNotifModalStatus: PropTypes.func.isRequired,
    apiCaller: PropTypes.func.isRequired,
    bellButtonRef: PropTypes.oneOfType([
        // Either a function
        PropTypes.func,
        // Or the instance of a DOM native element (see the note about SSR)
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    refreshProfile: PropTypes.func.isRequired,
    playSoundWhenNotifReceives: PropTypes.bool,
    playSoundWhenNudgeReceives: PropTypes.bool,
    broker: PropTypes.object,
    trader: PropTypes.object,
    updateProfileSettings: PropTypes.func.isRequired,
    updateBackdrop: PropTypes.func.isRequired
};

function Notification({
    notifModalStatus,
    setNotifModalStatus,
    apiCaller,
    bellButtonRef,
    refreshProfile,
    playSoundWhenNotifReceives,
    playSoundWhenNudgeReceives,
    broker,
    trader,
    updateProfileSettings,
    updateBackdrop
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [page, setPage] = useState(undefined);
    const [playNotifSound] = useSound(notifSound);
    const [playNudgeSound] = useSound(nudgeSound);
    const [playMessageSound] = useSound(nudgeSound);

    const isVisible = notifModalStatus !== NOTIF_MODAL_STATUS.HIDDEN;
    const fullSize = notifModalStatus === NOTIF_MODAL_STATUS.FULLSIZE;
    const notifsRef = useRef();
    const prevModalState = usePrevious(notifModalStatus);

    useOnClickOutside(notifsRef, useCallback(() => {
        setNotifModalStatus(NOTIF_MODAL_STATUS.HIDDEN);
        updateBackdrop(false);
    }, [setNotifModalStatus, updateBackdrop]), bellButtonRef);

    const onClickCloseNotifs = () => {
        setNotifModalStatus(NOTIF_MODAL_STATUS.HIDDEN);
        updateBackdrop(false);
        setPage(undefined);
        setHasMore(false);
        setNotifications([]);
    };

    const onClickViewAll = async() => {
        setNotifications([]);
        setNotifModalStatus(NOTIF_MODAL_STATUS.FULLSIZE);
        updateBackdrop(true);
        setPage(1);
    };

    const markNotificationsAsRead = useCallback(async(items) => {
        try {
            const ids = items.map(n => n.id);

            if (ids.length > 0) {
                await apiCaller(readNotifications, { ids });
            }

            refreshProfile();
        } catch (e) {
            console.error(e);
        }
    }, [apiCaller, refreshProfile]);

    const fetchNotifications = useCallback(async (count = 3) => {
        try {
            setIsLoading(true);
            const { data } = await apiCaller(getNotifications, count, page);

            setHasMore(data.current_page < data.last_page);

            setNotifications(notifs => {
                const newNotifs = page === undefined ? data.data : [
                    ...notifs,
                    ...data.data
                ];

                return newNotifs;
            });

            setIsLoading(false);
            markNotificationsAsRead(data.data);
        } catch (error) {
            if (error) {
                notify('An error occurred!', notifType.DANGER);
            }
        }
    }, [apiCaller, markNotificationsAsRead, page]);

    const renderNotification = (notification, key) => {
        try {
            let title = '';
            let message = '';
            let date = null;

            // later we need to handle several notification types
            title = notification.data.title;
            message = notification.data.message;
            date = parseISO(notification.created_at);

            return <div key={key} className={classNames('notifications__entry-wrapper', { 'notifications__entry-wrapper--full': fullSize })}>
                <div className='notifications__entry-head'>
                    <div className='notifications__entry-title'>
                        <img src={NotifIcon} alt='close' className='notifications__entry-icon' />
                        <div className='notifications__entry-party'>{title}</div>
                    </div>
                    <div className='notifications__entry-date'>{formatRelative(date, new Date())}</div>
                </div>
                <div className='notifications__entry-body'>
                    {message}
                </div>
            </div>;
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!prevModalState && notifModalStatus === NOTIF_MODAL_STATUS.VISIBLE) {
            // mark shown messages as read when open the notification modal
            fetchNotifications();
        }
    }, [apiCaller, fetchNotifications, notifModalStatus, prevModalState]);

    useEffect(() => {
        const onNewNotificationReceived = async(event) => {
            if (event.type === NOTIFICATION_TYPE.USER_NOTIF) {
                if (playSoundWhenNotifReceives) {
                    playNotifSound();
                }

                if (notifModalStatus === NOTIF_MODAL_STATUS.HIDDEN) {
                    // only need to update unread count
                    refreshProfile();
                } else {
                    setNotifications([event, ...notifications]);
                    await apiCaller(readNotification, event.id);
                }
            }

            if (event.type === NOTIFICATION_TYPE.NEGO_HAS_MESSAGE) {
                if (playSoundWhenNudgeReceives) {
                    if (trader) {
                        playMessageSound();
                    }
                }
            }
        };

        const onNudgeSoundRequested = async() => {
            if (playSoundWhenNudgeReceives) {
                playNudgeSound();
            }
        };

        // adding event listeners on mount here
        const listener = EventEmitter.addListener('NewNotificationReceived', onNewNotificationReceived);
        const soundListener = EventEmitter.addListener(NOTIFICATION_TYPE.USER_NUDGE_PLAY_SOUND, onNudgeSoundRequested);

        return () => {
            // cleaning up the listeners here
            listener.remove();
            soundListener.remove();
        };
    }, [apiCaller, broker, notifModalStatus, notifications, playNotifSound, playNudgeSound, playMessageSound, playSoundWhenNotifReceives, playSoundWhenNudgeReceives, refreshProfile, trader]);

    useEffect(() => {
        const onNewNotificationReceived = async(event) => {
            if (event.type !== NOTIFICATION_TYPE.USER_UPDATE) {
                return;
            }

            updateProfileSettings(event.data);
        };

        // adding event listeners on mount here
        const listener = EventEmitter.addListener('NewNotificationReceived', onNewNotificationReceived);

        return () => {
            // cleaning up the listeners here
            listener.remove();
        };
    }, [apiCaller, notifModalStatus, notifications, playNotifSound, playSoundWhenNotifReceives, refreshProfile, updateProfileSettings]);

    useEffect(() => {
        if (page) {
            fetchNotifications(100);
        }
    }, [fetchNotifications, page]);

    const fetchData = () => {
        setPage(oldPage => oldPage + 1);
    };

    return (
        !isVisible ? false
            : <div className={classNames('notifications', { 'notifications--full': fullSize })}>
                <div ref={notifsRef} className={classNames('notifications__container', { 'notifications__container--full': fullSize })}>
                    {fullSize ? null : <div className='notifications__pointer' />}
                    <div className={classNames('notifications__wrapper', { 'notifications__wrapper--full': fullSize })}>
                        {fullSize ? <div className='notifications__header'>
                            <img onClick={onClickCloseNotifs} src={CloseIcon} alt='close'
                                className='notifications__close-button' />

                            <div className='notifications__title'>
                                Notifications
                            </div>
                        </div> : null}
                        {isLoading && !fullSize ? <AppLoading showLogo={true} size={30} className='notifications__loader' /> : <div id='scrollableTargetNotifs' className={classNames('notifications__body', { 'notifications__body--full': fullSize })} >

                            { fullSize ? <InfiniteScroll
                                dataLength={notifications.length} // This is important field to render the next data
                                next={fetchData}
                                hasMore={hasMore}
                                loader={<h4 className='notifications__scroller-status'>Loading...</h4>}
                                scrollableTarget={'scrollableTargetNotifs'}
                                endMessage={<p className='notifications__scroller-status'>
                                    <b>All notifications have been read</b>
                                </p>}>
                                {notifications.map(renderNotification)}

                            </InfiniteScroll>
                                : notifications.map(renderNotification)
                            }

                        </div>}

                        {fullSize || isLoading ? null : <div onClick={onClickViewAll} className='notifications__view-all'>
                            View All Notifications
                        </div>}
                    </div>
                </div>
            </div>
    );
}

function mapStateToProps({ auth }) {
    // eslint-disable-next-line camelcase
    const { profile: { settings: { enable_notification_sound, enable_nudge_sound }, broker, trader } } = auth;

    return {
        playSoundWhenNotifReceives: enable_notification_sound,
        playSoundWhenNudgeReceives: enable_nudge_sound,
        broker: broker,
        trader: trader
    };
}

function mapDispatchToProps(dispatch) {
    return {

        updateProfileSettings: (settings) => {
            dispatch(updateProfileSettings(settings));
        }
    };
}

export default withAsyncCaller(connect(mapStateToProps, mapDispatchToProps)(Notification));
