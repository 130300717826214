import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import PropTypes from 'prop-types';
import { USER_LOCK_TIME } from 'config/constants';

LockScreen.propTypes = {
    children: PropTypes.any.isRequired,
    LockUserScreen: PropTypes.func.isRequired,
    lockIt: PropTypes.bool.isRequired,
    setLockIt: PropTypes.func.isRequired
};

function LockScreen({ children, LockUserScreen, lockIt, setLockIt }) {
    const [leader, setLeader] = useState(true);
    const [checkTab, setCheckTab] = useState(true);
    const [idInterval, setIdInterval] = useState('');

    const handleOnIdle = event => {
        if (leader && !lockIt) {
            // lock user
            setCheckTab(false);
            setLockIt(true);
            LockUserScreen();
        }
    };

    const { reset, getRemainingTime, start } = useIdleTimer({
        timeout: USER_LOCK_TIME,
        onIdle: handleOnIdle,
        debounce: 5000,
        stopOnIdle: true
    });

    const startTimer = () => {
        if (getRemainingTime() === 0) {
            start();
        }
    };

    useEffect(() => {
        if (checkTab && !lockIt) {
            const interval = setInterval(() => {
                if (document.visibilityState === 'visible') {
                    setLeader(true);
                    startTimer();
                } else {
                    setLeader(false);
                }
            }, 4500);

            setIdInterval(interval);
        } else {
            clearInterval(idInterval);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkTab, lockIt]);

    useEffect(() => {
        if (!lockIt) {
            reset();
            setCheckTab(true);
        }
    }, [lockIt, reset]);

    return (<div>{lockIt && children}</div>);
}

export default LockScreen;
