import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history } from 'redux/store';
import AppContainer from 'containers/App/AppContainer';
import 'config/execute';
import 'creatella-react-components/lib/styles.scss';
import 'styles/styles.scss';
import ModalProvider from 'provider/modal/ModalProvider';
import QueryProvider from 'provider/QueryProvider';

render(
    <Provider store={store}>
        <QueryProvider>
            <ModalProvider>
                <ConnectedRouter history={history}>
                    <AppContainer />
                </ConnectedRouter>
            </ModalProvider>
        </QueryProvider>
    </Provider>,
    document.getElementById('root')
);
